<template>
  <div class="grid gap-8">

    <router-link to="/dashboard" class="flex gap-2">
      <KeyboardArrowLeftIcon class="h-6" />
      <span class="text-lg leading-6 font-semibold">{{ $t('backToDashboard') }}</span>
    </router-link>

    <div v-if="store.application" class="container">
      <section v-if="!directusStore.userHasRole('applicant')" class="grid gap-4">
        <div class="grid grid-cols-[1fr_auto]  gap-2 md:gap-4">
          <div>
            <div class="font-bold text-2xl text-dark-green-900">{{ store.application.first_name }} {{
              store.application.last_name }}</div>
            <div class="text-dark-green-100">BCC {{ store.application.local_church?.name }}</div>
            <div class="text-dark-green-100">{{ getFormattedDate(store.application.birth_date) }}</div>
          </div>
          <div v-if="store.application.profile_picture">
            <img
              :src="directus._url + '/assets/' + store.application.profile_picture.id + '?access_token=' + store.access_token"
              class="rounded-lg" width="150" />
          </div>
          <div class="grid gap-6 col-span-full">
            <BccInput :label="t('form.motivation_and_expectation.title')" :value="store.application.motivation_and_expectation" class="col-span-full" type="text" is="textarea" rows="4" disabled />
            <BccInput :label="t('form.personal_description.title')" :value="store.application.personal_description" class="col-span-full" type="text" is="textarea" rows="4" disabled />
            <BccInput :label="t('form.preferable_tasks_in_local_church.title')" :value="store.application.preferable_tasks_in_local_church" class="col-span-full" type="text" is="textarea" rows="4" disabled />
          </div>
        </div>
        <form @submit.prevent="handleSubmit" class="grid gap-4 my-8">
          <div class="grid gap-8 mb-8">
            <h1 class="font-bold text-2xl md:text-3xl text-dark-green-900">{{ t('reference.title', {
              year: new
                Date().getFullYear() + 1
            }) }}</h1>

            <div class="whitespace-pre-wrap" v-html="store.page"></div>

            <div class="grid gap-2">
              <p class="font-semibold">1. {{ t('reference.form.norwegian_course') }}</p>
              <BccRadio :label="t('form.yes')" value="yes" v-model="store.norwegian_course" />
              <BccRadio :label="t('form.no')" value="no" v-model="store.norwegian_course" />
              <BccRadio :label="t('reference.form.norwegian_as_mothers_tongue')" value="norwegian_as_mothers_tongue" v-model="store.norwegian_course" />
              <OthersReference :references="store.othersReference" ref_type="norwegian_course" />
            </div>

            <div class="grid gap-2">
              <p class="font-semibold">2. {{ t('reference.form.personality2') }}</p>
              <BccInput v-model="store.personality2" class="col-span-full"
                :placeholder="t('form.inputPlaceholder')" type="text" is="textarea" rows="4" />
              <OthersReference :references="store.othersReference" ref_type="personality2" />
            </div>

            <div class="grid gap-2">
              <p class="font-semibold">3a. {{ t('reference.form.tasks_in_local_church') }}</p>
              <BccInput v-model="store.tasks_in_local_church" class="col-span-full"
                :placeholder="t('form.inputPlaceholder')" type="text" is="textarea" rows="4" />
              <OthersReference :references="store.othersReference" ref_type="tasks_in_local_church" />
            </div>

            <div class="col-span-full print:!hidden">
              <p class="font-semibold mb-4">3b. {{ t('reference.form.applicant_abilities.title') }}</p>
              <div class="relative col-span-full">
                <table class="w-full text-left">
                  <thead class="sticky top-0 z-[3]">
                    <tr>
                      <th class="py-4 px-4 min-w-[200px] sticky left-0 z-[3] bg-isbre-700 rounded-tl-lg" scope="col">{{ t('form.knowledgeExperienceAreas.area') }}</th>
                      <th class="py-4 px-4 min-w-[50px] bg-isbre-700 text-center" :class="index == 5 ? 'rounded-tr-lg' : ''" scope="col" v-for="index in 5" :key="index">{{ index }}</th>
                    </tr>
                  </thead>
                  <tbody class="relative z-[1]">
                    <tr class="bg-white border-b border-neutral-300">
                      <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                        scope="row">{{ t('reference.form.applicant_abilities.collaboration_with_responsibles') }}</td>
                      <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                        <BccRadio name="collaboration_with_responsibles" :value="index" v-model="store.collaboration_with_responsibles" />
                      </td>
                    </tr>
                    <tr class="bg-white border-b border-neutral-300">
                      <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                        scope="row">{{ t('reference.form.applicant_abilities.collaboration_with_peers') }}</td>
                      <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                        <BccRadio name="collaboration_with_peers" :value="index" v-model="store.collaboration_with_peers" />
                      </td>
                    </tr>
                    <tr class="bg-white border-b border-neutral-300">
                      <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                        scope="row">{{ t('reference.form.applicant_abilities.on_time') }}</td>
                      <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                        <BccRadio name="on_time" :value="index" v-model="store.on_time" />
                      </td>
                    </tr>
                    <tr class="bg-white border-b border-neutral-300">
                      <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                        scope="row">{{ t('reference.form.applicant_abilities.adapt_to_new_social_situations') }}</td>
                      <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                        <BccRadio name="adapt_to_new_social_situations" :value="index" v-model="store.adapt_to_new_social_situations" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <OthersReference :references="store.othersReference" ref_type="applicant_abilities" />
            </div>

            <div class="grid gap-2">
              <p class="font-semibold">3c. {{ t('reference.form.workparty_contribution_and_cooperation') }}</p>
              <BccInput v-model="store.workparty_contribution_and_cooperation" class="col-span-full"
                :placeholder="t('form.inputPlaceholder')" type="text" is="textarea" rows="4" />
              <OthersReference :references="store.othersReference" ref_type="workparty_contribution_and_cooperation" />
            </div>

            <div class="grid gap-2">
              <p class="font-semibold">4. {{ t('reference.form.is_approved_local_worker.title') }}</p>
              <BccRadio :label="t('form.yes')" value="yes" v-model="store.is_approved_local_worker" />
              <BccRadio :label="t('form.under_18')" value="under_18" v-model="store.is_approved_local_worker" />
              <BccRadio :label="t('form.no')" value="no" v-model="store.is_approved_local_worker" />
              <OthersReference :references="store.othersReference" ref_type="is_approved_local_worker" />
            </div>

            <div class="grid gap-2">
              <p class="font-semibold" v-html="'5. ' + $t('reference.form.responsibility.title')"></p>
              <em class="italic text-sm -mt-1 opacity-80 block">{{ t('reference.form.choose_one') }}</em>
              <BccRadio :label="t('reference.form.responsibility.completion')" value="completion" v-model="store.responsibility" />
              <BccRadio :label="t('reference.form.responsibility.with_help')" value="with_help" v-model="store.responsibility" />
              <BccRadio :label="t('reference.form.responsibility.needs_guidance')" value="needs_guidance" v-model="store.responsibility" />
              <OthersReference :references="store.othersReference" ref_type="responsibility" />
            </div>

            <div class="grid gap-2">
              <p class="font-semibold">6. {{ t('reference.form.driver.title') }}</p>
              <em class="italic text-sm -mt-1 opacity-80 block">{{ t('reference.form.driver.note') }}</em>
              <BccRadio :label="t('form.yes')" value="advise_against" v-model="store.driver" />
              <BccRadio :label="t('form.no')" value="dont_know" v-model="store.driver" />
              <OthersReference :references="store.othersReference" ref_type="driver" />
            </div>

            <div class="grid gap-2 special-circumstances">
              <div class="font-semibold" v-html="'7. ' + $t('reference.form.special_circumstances.title')"></div>
              <em class="italic text-sm -mt-1 opacity-80 block">{{ t('reference.form.special_circumstances.note') }}</em>
              <BccRadio :label="t('form.yes')" value="yes" v-model="store.special_circumstances_radio" />
              <BccRadio :label="t('form.no')" value="no" v-model="store.special_circumstances_radio" />
              <OthersReference :references="store.othersReference" ref_type="special_circumstances_radio" />

              <div class="grid gap-4 p-4 border rounded-sm mt-2" v-if="store.special_circumstances_radio == 'yes'">
                <div class="text-sm">{{ t('reference.form.contact.title') }}</div>
                <div class="grid md:grid-cols-2 gap-4">
                  <div class="grid gap-2">
                    <p class="font-semibold">{{ t('reference.form.contact.name') }}</p>
                    <BccInput v-model="store.special_circumstances_contact_name" class="col-span-full"
                      :placeholder="t('form.inputPlaceholder')" type="text" />
                  </div>
                  <div class="grid gap-2">
                    <p class="font-semibold">{{ t('reference.form.contact.phoneNumber') }}</p>
                    <BccInput v-model="store.special_circumstances_contact_phone_number" class="col-span-full"
                      :placeholder="t('form.inputPlaceholder')" type="text" />
                  </div>
                </div>
              </div>
            </div>
            
            <div class="grid gap-2">
              <p class="font-semibold">8. {{ t('reference.form.work_life.title') }}</p>
              <em class="italic text-sm -mt-1 opacity-80 block">{{ t('reference.form.work_life.note') }}</em>
              <BccInput v-model="store.work_life" class="col-span-full"
                :placeholder="t('form.inputPlaceholder')" type="text" is="textarea" rows="4" />
              <OthersReference :references="store.othersReference" ref_type="work_life" />
            </div>

            <div class="grid gap-2">
              <p class="font-semibold whitespace-pre-wrap">9. {{ t('reference.form.conversation_with_the_applicant.title') }}</p>
              <em class="italic text-sm -mt-1 opacity-80 block">{{ t('reference.form.conversation_with_the_applicant.note') }}</em>
              <BccRadio :label="t('form.yes')" value="yes" v-model="store.conversation_with_the_applicant" />
              <BccRadio :label="t('form.no')" value="no" v-model="store.conversation_with_the_applicant" />
              <OthersReference :references="store.othersReference" ref_type="conversation_with_the_applicant" />
            </div>

            <div class="grid gap-2">
              <p class="font-semibold">10. {{ t('reference.form.approval.title') }}</p>
              <em class="italic text-sm -mt-1 opacity-80 block">{{ t('reference.form.approval.note') }}</em>
              <BccRadio :label="t('form.yes')" value="yes" v-model="store.approval" />
              <BccRadio :label="t('form.no')" value="no" v-model="store.approval" />
              <BccRadio :label="t('form.recommend_to_wait')" value="recommend_to_wait" v-model="store.approval" />
              <BccRadio :label="t('form.needs_dialog')" value="needs_dialog" v-model="store.approval" />
              <OthersReference :references="store.othersReference" ref_type="approval" />
              <p class="text-sm mt-2 opacity-80 block">{{ t('reference.form.approval.confirmation_note') }}</p>
            </div>

            <div class="grid gap-2">
              <p class="font-semibold">11. {{ t('reference.form.dialog_required.title') }}</p>
              <em class="italic text-sm -mt-1 opacity-80 block">{{ t('reference.form.dialog_required.note') }}</em>
              <BccRadio :label="t('reference.form.dialog_required.needed')" value="yes" v-model="store.dialog_required" />
              <BccRadio :label="t('reference.form.dialog_required.notNecessary')" value="no" v-model="store.dialog_required" />
              <OthersReference :references="store.othersReference" ref_type="dialog_required" />

              <div class="grid gap-4 p-4 border rounded-sm mt-2" v-if="store.dialog_required == 'yes'">
                <div class="text-sm">{{ t('reference.form.contact.title') }}</div>
                <div class="grid md:grid-cols-2 gap-4">
                  <div class="grid gap-2">
                    <p class="font-semibold">{{ t('reference.form.contact.name') }}</p>
                    <BccInput v-model="store.dialog_contact_name" class="col-span-full"
                      :placeholder="t('form.inputPlaceholder')" type="text" />
                  </div>
                  <div class="grid gap-2">
                    <p class="font-semibold">{{ t('reference.form.contact.phoneNumber') }}</p>
                    <BccInput v-model="store.dialog_contact_phone_number" class="col-span-full"
                      :placeholder="t('form.inputPlaceholder')" type="text" />
                  </div>
                </div>
              </div>
            </div>

            <div class="grid gap-2">
              <p class="font-semibold">12. {{ t('reference.form.comment') }}</p>
              <BccInput v-model="store.comment" class="col-span-full"
                :placeholder="t('form.inputPlaceholder')" type="text" is="textarea" rows="4" />
              <OthersReference :references="store.othersReference" ref_type="comment" />
            </div>

            <div class="grid gap-2">
              <p class="font-semibold">13. {{ t('reference.form.contributors.title') }}</p>
              <div v-for="(contributor, index) in store.contributors" :key="index" class="relative">
                <BccInput v-model="contributor.name" :data-index="index" class="w-full"
                  :placeholder="t('reference.form.contributors.placeholder')" type="text" />
                <span
                  class="absolute right-2 top-2 rounded-full text-dark-green-100 transition hover:bg-dark-green-100 hover:text-white material-symbols-rounded ml-auto cursor-pointer"
                  @click="removeContributor">cancel</span>
              </div>

              <BccButton variant="secondary" :icon="AddIcon" class="mr-auto" @click="addContributor">
                {{ t('reference.form.contributors.add') }}
              </BccButton>
              <OthersReference :references="store.othersReference" ref_type="contributors" hide="true" />
            </div>          
          </div>

          <BccAlert context="danger" icon v-show="store.updateFailed" class="col-span-full">{{ t('form.errors.referenceUpdateFailed') }}</BccAlert>

          <BccButton variant="primary" class="mr-auto" :disabled="store.formLoading"
            :class="{ 'cursor-not-allowed': store.formLoading }" type="submit">
            <div class="spinner" :class="{ loading: store.formLoading }"></div>
            {{ t('form.save') }}
          </BccButton>
        </form>
      </section>
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, reactive, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useDirectusStore } from '../stores/directus'
import { getFormattedDate } from '../helpers/fields.js'
import OthersReference from '../components/OthersReference'
import { useRoute, useRouter } from 'vue-router'

import { KeyboardArrowLeftIcon, AddIcon } from '@bcc-code/icons-vue'
import { BccButton, BccCheckbox, BccInput, BccRadio, BccAlert } from '@bcc-code/design-library-vue'

const { t, locale } = useI18n({
  inheritLocale: true,
  useScope: 'local'
})

const route = useRoute()
const router = useRouter()
const directusStore = useDirectusStore()
const user = directusStore.user
const directus = directusStore.directus

const store = reactive({
  access_token: null,
  page: null,
  application: null,
  myReferenceId: null,
  othersReference: [],

  updateFailed: false,
  formLoading: false,

  norwegian_course: null,
  personality2: "",
  tasks_in_local_church: "",
  collaboration_with_responsibles: null,
  collaboration_with_peers: null,
  on_time: null,
  adapt_to_new_social_situations: null,
  workparty_contribution_and_cooperation: "",
  is_approved_local_worker: null,
  responsibility: null,
  driver: null,
  special_circumstances_radio: null,
  special_circumstances_contact_name: "",
  special_circumstances_contact_phone_number: "",
  work_life: "",
  conversation_with_the_applicant: null,
  approval: null,
  dialog_required: null,
  dialog_contact_name: "",
  dialog_contact_phone_number: "",
  comment: "",
  contributors: []
})

onBeforeMount(async () => {
  store.access_token = await directus.auth.staticToken

  await getPage()
  await getApplication()
})

watch(
  locale,
  () => {
    store.othersReference = []
    store.application.references.forEach(reference => {
      // Other's reference
      if (reference.user_created.id != user.id) {
        const translatedReference = getTranslatedFields(reference)
        store.othersReference.push(translatedReference)
      }
    })
  }
)

async function getPage() {
  const pages = await directus.items("pages").readByQuery({
    fields: ['*']
  })
  if (!pages.data.length) return
  
  store.page = pages.data.find(page => page.slug == 'reference')?.content
}

async function getApplication() {
  // Get application
  const application = await directus.items("applications").readByQuery({
    filter: {
      id: { _eq: route.params.id },
    },
    fields: ['*', 'references.*.*', 'local_church.*', 'profile_picture.*'],
  })
  if (!application.data.length) return

  store.application = application.data[0]

  // Reference person overview
  if (!store.application.references.filter(r => r.user_created.id == user.id).length) {
    // New reference
    store.myReferenceId = 0
  }

  store.application.references.forEach(reference => {
    // Personal reference
    if (reference.user_created.id == user.id) {
      store.myReferenceId = reference.id
      store.norwegian_course = reference.norwegian_course
      store.personality2 = reference.personality2
      store.tasks_in_local_church = reference.tasks_in_local_church
      store.collaboration_with_responsibles = reference.collaboration_with_responsibles
      store.collaboration_with_peers = reference.collaboration_with_peers
      store.on_time = reference.on_time
      store.adapt_to_new_social_situations = reference.adapt_to_new_social_situations
      store.workparty_contribution_and_cooperation = reference.workparty_contribution_and_cooperation
      store.is_approved_local_worker = reference.is_approved_local_worker
      store.responsibility = reference.responsibility
      store.driver = reference.driver
      store.special_circumstances_radio = reference.special_circumstances_radio
      store.special_circumstances_contact_name = reference.special_circumstances_contact_name
      store.special_circumstances_contact_phone_number = reference.special_circumstances_contact_phone_number
      store.work_life = reference.work_life
      store.conversation_with_the_applicant = reference.conversation_with_the_applicant
      store.approval = reference.approval
      store.dialog_required = reference.dialog_required
      store.dialog_contact_name = reference.dialog_contact_name
      store.dialog_contact_phone_number = reference.dialog_contact_phone_number
      store.comment = reference.comment
      store.contributors = reference.contributors || []
    }
    // Other's reference
    else {
      const translatedReference = getTranslatedFields(reference)
      store.othersReference.push(translatedReference)
    }
  })
}

function getTranslatedFields(ref) {
  const reference = JSON.parse(JSON.stringify(ref))

  reference.norwegian_course = reference.norwegian_course ? t('form.' + reference.norwegian_course) : null
  reference.is_approved_local_worker = reference.is_approved_local_worker ? t('form.' + reference.is_approved_local_worker) : null
  reference.responsibility = reference.responsibility ? t('reference.form.responsibility.' + reference.responsibility) : null
  reference.driver = reference.driver ? t('reference.form.driver.' + reference.driver) : null
  reference.special_circumstances_radio = reference.special_circumstances_radio ? t('form.' + reference.special_circumstances_radio) : null
  reference.conversation_with_the_applicant = reference.conversation_with_the_applicant ? t('form.' + reference.conversation_with_the_applicant) : null
  reference.approval = reference.approval ? t('form.' + reference.approval) : null
  reference.dialog_required = reference.dialog_required ? (reference.dialog_required == 'yes' ? t('reference.form.dialog_required.needed') : t('reference.form.dialog_required.notNecessary')) : null
  reference.contributors = reference.contributors?.map(function (item) { return item['name'] }).join(', ')

  reference.applicant_abilities = 
    (reference.collaboration_with_responsibles ? `${t('reference.form.applicant_abilities.collaboration_with_responsibles')}: ${reference.collaboration_with_responsibles}\n` : '') +
    (reference.collaboration_with_peers ? `${t('reference.form.applicant_abilities.collaboration_with_peers')}: ${reference.collaboration_with_peers}\n` : '') + (reference.on_time ?
    `${t('reference.form.applicant_abilities.on_time')}: ${reference.on_time}\n` : '') + (reference.adapt_to_new_social_situations ?
    `${t('reference.form.applicant_abilities.adapt_to_new_social_situations')}: ${reference.adapt_to_new_social_situations}` : '')

  return reference
}

function addContributor(e) {
  e.preventDefault()
  store.contributors.push({ name: '' })
}

function removeContributor(e) {
  e.preventDefault()
  store.contributors.splice(e.target.previousSibling.getAttribute('data-index'), 1)
}

async function handleSubmit() {
  store.formLoading = true

  const data = {
    norwegian_course: store.norwegian_course,
    personality2: store.personality2,
    tasks_in_local_church: store.tasks_in_local_church,
    collaboration_with_responsibles: store.collaboration_with_responsibles,
    collaboration_with_peers: store.collaboration_with_peers,
    on_time: store.on_time,
    adapt_to_new_social_situations: store.adapt_to_new_social_situations,
    workparty_contribution_and_cooperation: store.workparty_contribution_and_cooperation,
    is_approved_local_worker: store.is_approved_local_worker,
    responsibility: store.responsibility,
    driver: store.driver,
    special_circumstances_radio: store.special_circumstances_radio,
    special_circumstances_contact_name: store.special_circumstances_contact_name,
    special_circumstances_contact_phone_number: store.special_circumstances_contact_phone_number,
    work_life: store.work_life,
    conversation_with_the_applicant: store.conversation_with_the_applicant,
    approval: store.approval,
    dialog_required: store.dialog_required,
    dialog_contact_name: store.dialog_contact_name,
    dialog_contact_phone_number: store.dialog_contact_phone_number,
    comment: store.comment,
    contributors: store.contributors,
  }

  if (!store.myReferenceId) {
    data.application_id = store.application.id
  }

  await sendReference(data)
  store.formLoading = false
}

async function sendReference(data) {
  try {
    store.myReferenceId > 0
      ? await directus.items('references').updateOne(store.myReferenceId, data)
      : await directus.items('references').createOne(data)

    router.push({ path: '/dashboard' })
  }
  catch (error) {
    store.updateFailed = true
    console.error("There was an error!", error)
  }
}
</script>

<style>
  .special-circumstances ul {
    list-style: inside;
  }
</style>