<template>
    <aside class="sticky top-0 max-h-screen z-50 flex flex-col flex-shrink-0 h-full p-3 bg-secondary transition duration-200 ease-out md:z-auto md:w-[300px] print:hidden" :class="`${isExpanded ? 'is_expanded w-[300px]' : 'w-[4.5rem]'}`">
        <BccButton variant="secondary" :icon="isExpanded ? KeyboardDoubleArrowLeftIcon : KeyboardDoubleArrowRightIcon" @click="ToggleMenu" class="mx-auto mb-4 md:hidden" />
        <Menu />
        <LanguageSwitcher />
    </aside>
</template>

<script setup>
import Menu from "./Menu"
import LanguageSwitcher from "./LanguageSwitcher"
import { BccButton } from "@bcc-code/design-library-vue"
import { KeyboardDoubleArrowRightIcon, KeyboardDoubleArrowLeftIcon } from "@bcc-code/icons-vue"

import { ref } from "vue"

const isExpanded = ref(false)

const ToggleMenu = () => {
    isExpanded.value = !isExpanded.value
}

</script>
<style lang="scss">
aside {
    >nav>a>span {
        display: none;
    }

    >nav>div {
        display: none;
    }

    .v-select {
        display: none;
    }
}

aside.is_expanded {
    >.bcc-button {
        margin-right: 0;
    }

    >nav>a>span {
        display: flex
    }

    >nav>div {
        display: flex;
    }

    .v-select {
        display: flex;
    }
}

@media(min-width: 768px) {
    aside {
        >nav>a>span {
            display: flex;
        }

        >nav>div {
            display: flex;
        }

        .v-select {
            display: flex;
        }
    }
}
</style>
