function getFileSize(data) {
    const const_term = 1024
    if (data > const_term ** 2) return (data / const_term ** 2).toFixed(0) + " MB"
    else return (data / const_term).toFixed(0) + " KB"
}

function getFileExtension(type) {
    const fileType = type.split('.').pop().toLowerCase()
    if (fileType == 'pdf') return 'picture_as_pdf'
    else if (['jpg','jpeg','png','gif','tiff','jfif','bmp'].includes(fileType)) return 'image'
    else if (['mp4','mov','avi','wmv','flv','mkv','webm','mpeg','m4v','3gp'].includes(fileType)) return 'video_camera_back'
    else return 'description'
}

function getFileName(id) {
    if (id == 'cv') return 'CV'
    else if (id == 'profile_picture') return 'Profilbilde'
    else if (id == 'passport_id_card') return 'Pass / ID-kort'
    else if (id == 'application_clip') return 'Søknadsklipp'
    else if (id == 'european_health_insurance_card') return 'Europeisk helsetrygdkort'
    else if (id == 'international_insurance') return 'Internasjonal forsikring'
    else if (id == 'driver_license_copy') return 'Førerkort'
    else if (id == 'certificates') return 'Sertifikater'
    else if (id == 'vaccination_certificate_copy') return 'Vaksinasjonssertifikat'
    else if (id == 'social_security_card') return 'SV Ausweis'
    else if (id == 'criminal_record_check') return 'Politiattest'
    else return 'Dokument'
}

export { getFileSize, getFileExtension, getFileName }