<template>
  <div v-if="user" class="grid gap-8">
    <div class="container min-w-0">
      <header class="flex gap-6 mb-4 flex-col md:flex-row">
        <router-link to="/" class="disable-link flex gap-2">
          <span class="text-xl leading-6 font-semibold">{{ $t('menu.placements') }}</span>
        </router-link>

        <BccInput class="md:ml-auto" v-model="store.search_name" :placeholder="t('dashboard.filter.search')" />
      </header>

      <div class="flex gap-2 mb-4">
        <v-select v-if="store.potentialPlacements.length" class="w-72" v-model="store.search_potentialPlacement"
          :placeholder="t('dashboard.potential_placement.title')" :reduce="option => option.value"
          :options="store.potentialPlacements" />

        <v-select v-if="store.employers.length > 1" v-model="store.employer_id" class="w-72"
          :placeholder="t('dashboard.filter.employer')" :reduce="option => option.value" :options="store.employers"
          :clearable="false" />
      </div>

      <BccTable :columns="columns()" :items="sortedApplications" v-model:sortBy="store.sortBy"
        v-model:sortDirection="store.sortDirection" class="max-w-full overflow-x-auto quick-fix_white-space">

        <template #item.display.potential_placement="{ item }">
          <BccBadge v-for="placement in item.display.potential_placement" context="info" class="mr-1">
            {{ t('dashboard.potential_placement.' + placement) }}
          </BccBadge>
        </template>

        <template #item.actions="{ item }">
          <BccButton variant="tertiary" size="sm" :padding="false" :icon="ChevronRightIcon" iconRight
            @click="evaluateApplication(item)">{{ getButton(item) }} </BccButton>
        </template>

      </BccTable>

      <div v-if="store.isLoading" class="text-center w-full my-4">
        <BccButton>{{ t('loading') }}</BccButton>
      </div>

      <div v-if="!store.isLoading && !sortedApplications.length" class="m-4">
        <p>{{ t('dashboard.noApplicationAvailable') }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, reactive, watch, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useDirectusStore } from '../stores/directus'

import { BccButton, BccInput, BccTable, BccBadge } from '@bcc-code/design-library-vue'
import { ChevronRightIcon } from '@bcc-code/icons-vue'

import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"
import { useRouter } from 'vue-router'

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
})

const router = useRouter()
const directusStore = useDirectusStore()
const user = directusStore.user
const directus = directusStore.directus

const store = reactive({
  isLoading: true,
  potentialPlacements: [],
  search_potentialPlacement: localStorage.getItem('potentialPlacement'),
  search_name: '',
  applications: [],
  employers: [],
  employer_id: 0,
  sortBy: 'display.name',
  sortDirection: 'ascending'
})

const columns = () => [
  {
    text: t('dashboard.name'),
    key: 'display.name'
  },
  {
    text: 'YEP',
    key: 'display.yep'
  },
  {
    text: t('dashboard.potential_placement.title'),
    key: 'display.potential_placement',
    sortable: false
  },
  {
    text: t('dashboard.rating'),
    key: 'display.rating'
  },
  {
    text: t('dashboard.actions.title'),
    key: 'actions',
    sortable: false
  }
]

onBeforeMount(async () => {
  if (directusStore.userHasRole('applicant'))
    router.push({ path: '/' })

  if (!directusStore.isAdmin()) await getEmployers()
  await getApplications()
})

function transformApplicationsData(data) {
  return data.map(item => {
    const display = {}

      display.name = `${item.first_name} ${item.last_name}`
      display.yep = t('form.yesOrNo.' + item.yep)
      display.potential_placement = item.potential_placement
      display.rating = getEmployerEvaluation(item)

      return { ...item, display }
    })
}

const sortedApplications = computed(() => {
  return store.applications
    .filter(a => {
      if (!store.employer_id) return true
      if (!a.potential_placement) return false

      const tags = user.employments
        .filter(e => e.employer_id.id == store.employer_id)
        .map(e => e.employer_id.tags)
        .flat()

      return a.potential_placement.some(p => {
        return tags.includes(p)
      })
    })
    .filter(a => {
      if (!a.potential_placement) return false

      return store.search_potentialPlacement
        ? a.potential_placement.includes(store.search_potentialPlacement) : true
    })
    .filter(a => {
      return (a.first_name + ' ' + a.last_name).toLowerCase().includes(store.search_name.toLowerCase())
        || (a.last_name + ' ' + a.first_name).toLowerCase().includes(store.search_name.toLowerCase())
    })
})

watch(
  () => store.search_potentialPlacement,
  (tag) => {
    tag != null
      ? localStorage.setItem('potentialPlacement', tag)
      : localStorage.removeItem('potentialPlacement')
  }
)

async function getApplications() {
  const applications = await directus.items("applications").readByQuery({
    filter: {
      status: {
        _in: [
          'ready_for_interview',
          'interview_scheduled',
          'ready_for_placement',
          'placement_confirmed',
          'active_participation'
        ]
      }
    },
    fields: [
      'id', 'first_name', 'last_name', 'yep', 'potential_placement', 'status',
      'interests', 'interests.id', 'interests.rate', 'interests.employer_id.id',
    ],
    limit: -1
  })

  store.applications = transformApplicationsData(applications.data)

  // Filter applications for employer
  if (directusStore.userHasRole('employer')) {
    const tags = user.employments.map(e => e.employer_id?.tags).flat()

    store.applications = store.applications.filter(a => {
      if (a.potential_placement == null)
        return false

      return a.potential_placement.some(p => {
        return tags.includes(p)
      })
    })
  }

  // Get potential placements from applications
  const potential_placements = store.applications
    .filter(a => a.potential_placement)
    .map(a => {
      return a.potential_placement
    })
    .flat()
    .map(placement => {
      return { 'value': placement, 'label': t('dashboard.potential_placement.' + placement) }
    })
    .sort((a, b) => {
      if (a.label < b.label) return -1
      if (a.label > b.label) return 1
      return 0
    })

  // Set unique values
  store.potentialPlacements = [...new Map(potential_placements.map(c => [c.label, c])).values()]

  store.isLoading = false
}

async function getEmployers() {
  if (!user.employments.length)
    return

  const employers = await directus.items("partner_companies").readByQuery({
    filter: {
      id: { _in: user.employments.map(e => e.employer_id?.id) }
    },
    fields: ['id', 'legal_entity_name']
  })

  if (!employers.data.length) return

  store.employers = employers.data
    .map(a => { return { 'value': a.id, 'label': a.legal_entity_name } })
    .sort((a, b) => {
      if (a.label < b.label) return -1
      if (a.label > b.label) return 1
      return 0
    })

  store.employer_id = store.employers[0].value
}

function getEmployerEvaluation(application) {
  return application.interests.find(i => i.employer_id?.id == store.employer_id)?.rate || '-'
}

function evaluateApplication(application) {
  router.push({ path: '/application/' + application.id + '/employer/' + store.employer_id })
}

function getButton(application) {
  return application.interests.find(i => i.employer_id?.id == store.employer_id)
    ? t('dashboard.actions.editEvaluation')
    : t('dashboard.actions.evaluateApplication')
}
</script>