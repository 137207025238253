<template>
    <div class="flex flex-col h-full">
        <Topbar />
        <div class="flex flex-grow" v-if="authStore.auth0 && directusStore.user">
            <Sidebar />
            <main class="flex-grow p-4 md:p-6 print:px-0">
                <router-view  />
            </main>
        </div>
        <div v-else class="flex h-full justify-center items-center">
            <BccSpinner size="xs" />
        </div>
    </div>
</template>

<script setup>
import { onBeforeMount } from 'vue';
import { useAuthStore } from './stores/auth'
import { useDirectusStore } from './stores/directus'
import { BccSpinner } from '@bcc-code/design-library-vue';
import Topbar from "./components/Topbar"
import Sidebar from "./components/Sidebar"

const authStore = useAuthStore()
const directusStore = useDirectusStore()

onBeforeMount(async () => {
    await authStore.setupAuth()
    await directusStore.setupDirectus()
    await directusStore.setUser()
})
</script>