<template>
  <div class="grid gap-4">
    <router-link to="/finance" class="flex gap-2">
      <KeyboardArrowLeftIcon class="h-6" />
      <span class="text-lg leading-6 font-semibold">{{ $t('finance.successfulPayment.title') }}</span>
    </router-link>

    <div class="grid md:grid-cols-2 gap-6 md:gap-8">
      <div class="col-span-full">
        <p>{{ t('finance.successfulPayment.thankYou') }}</p>
        <p
          v-html="t('finance.successfulPayment.questions', { email: '<a href=\'mailto:ateam@bcc.no\'>ateam@bcc.no</a>' })">
        </p>

      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { KeyboardArrowLeftIcon } from '@bcc-code/icons-vue'

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
})
</script>