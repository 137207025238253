<template>
  <div v-if="directusStore.user" class="grid gap-8">
    <div class="container min-w-0">
      <header class="flex gap-6 mb-4 flex-col md:flex-row">
        <router-link to="/" class="disable-link flex gap-2">
          <span class="text-xl leading-6 font-semibold">{{ $t('menu.dashboard') }}</span>
        </router-link>

        <BccInput
          v-if="isMultiReferencePerson()"
          class="md:ml-auto"
          v-model="store.search_name"
          :placeholder="t('dashboard.filter.search')"
        />
      </header>

      <div class="mb-4" v-if="isMultiReferencePerson()">
        <v-select
          v-if="store.localChurches"
          class="w-60"
          v-model="store.search_localChurch"
          :placeholder="t('dashboard.filter.church')"
          :reduce="(option) => option.value"
          :options="store.localChurches"
        />
      </div>

      <BccTable
        :columns="
          columns()
            .filter(
              (col) => (!canEvaluate() && col.key == 'status' ||
                !canEvaluate() && col.key == 'display.local_church') === false
            )
        "
        :items="filteredApplications"
        v-model:sortBy="store.sortBy"
        v-model:sortDirection="store.sortDirection"
        class="max-w-full overflow-x-auto"
      >

        <template #item.status="{ item }" v-if="canEvaluate()">
          <BccBadge v-if="item.references.length" context="success">{{
            t("dashboard.status.done", {
              count: item.references.length,
            })
          }}</BccBadge>
          <BccBadge v-else-if="item.status == 'draft'" context="neutral">{{
            t("dashboard.status.draft")
          }}</BccBadge>
          <BccBadge v-else context="warning">{{
            t("dashboard.status.waiting")
          }}</BccBadge>
        </template>

        <template #item.actions="{ item }">
          <BccButton
            variant="tertiary"
            size="sm"
            :padding="false"
            :icon="ChevronRightIcon"
            iconRight
            @click="getButtonLink(item)"
            >{{ getButtonLabel(item) }}</BccButton
          >
        </template>

      </BccTable>

      <div v-if="store.isLoading" class="w-full text-center my-4">
        <BccButton>{{ t('loading') }}</BccButton>
      </div>

      <div v-if="!store.isLoading && !filteredApplications.length" class="m-4">
        <p v-if="directusStore.userHasRole('applicant')">
          {{ t("dashboard.noPersonalApplication") }}
          <router-link
            to="/registration"
            class="font-bold text-dark-green-100 hover:opacity-80"
          >
            {{ t("dashboard.here") }} </router-link
          >.
        </p>
        <p v-else>{{ t("dashboard.noChurchApplication") }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { onBeforeMount, reactive, watch, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useDirectusStore } from "../stores/directus";
import { getFormattedDate } from "../helpers/fields.js";
import {
  BccTable,
  BccBadge,
  BccButton,
  BccInput,
} from "@bcc-code/design-library-vue";
import { ChevronRightIcon } from "@bcc-code/icons-vue";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

const { t } = useI18n({
  inheritLocale: true,
  useScope: "local",
});

const store = reactive({
  isLoading: true,
  applications: [],
  localChurches: [],
  search_name: "",
  search_localChurch: localStorage.getItem('localChurch') ? parseInt(localStorage.getItem('localChurch')) : null,
  sortBy: "display.date_created",
  sortDirection: "descending"
});

const router = useRouter();
const directusStore = useDirectusStore();
const user = directusStore.user;
const directus = directusStore.directus;

watch(
  () => store.search_localChurch,
  (tag) => {
    tag != null
      ? localStorage.setItem('localChurch', tag)
      : localStorage.removeItem('localChurch')
  }
)

const columns = () => [
  {
    text: t("dashboard.date"),
    key: "display.date_created"
  },
  {
    text: t("dashboard.name"),
    key: "display.name"
  },
  {
    text: t("dashboard.local_church"),
    key: "display.local_church"
  },
  {
    text: t("dashboard.status.title"),
    key: "status",
    sortMethod: (a, b) => {
      let modifier = 1;
      if (store.sortDirection === "descending") modifier = -1;

      if (a.references.length < b.references.length) return -1 * modifier;
      if (a.references.length > b.references.length) return 1 * modifier;
      return 0;
    }
  },
  {
    text: t("dashboard.actions.title"),
    key: "actions",
    sortable: false
  }
];

onBeforeMount(async () => {
  if (directusStore.userHasRole("employer")) {
    router.push({ path: "/dashboard/employments" });
  }
  else if (directusStore.userHasRole("stedsansvarlig")) {
    router.push({ path: "/dashboard/participants" });
  }
  else if (directusStore.userHasRole("norwegian_teacher")) {
    router.push({ path: "/dashboard/language/" });
  }

  await getApplications();
});

const filteredApplications = computed(() => {
  return store.applications
    .filter((item) => item.year_group == getNextYearGroup() || !item.year_group) // Filter only next year group & no possible_start_date
    .filter((item) => {
      return store.search_localChurch
        ? item.local_church?.id == store.search_localChurch
        : true;
    })
    .filter((item) => {
      return (
        `${item.first_name} ${item.last_name}`
          .toLowerCase()
          .includes(store.search_name.toLowerCase()) ||
        `${item.first_name} ${item.last_name}`
          .toLowerCase()
          .includes(store.search_name.toLowerCase())
      );
    });
});

function transformApplicationsData(data) {
  return data.map((item) => {
    const display = {};

    display.date_created = getFormattedDate(item.date_created);
    display.name = `${item.first_name} ${item.last_name}`;
    display.local_church = item.local_church ? item.local_church.name : '-';

    return { ...item, display };
  });
}

async function getApplications() {
  let filter = {};
  let fields = [
    "id",
    "date_created",
    "created_by",
    "first_name",
    "last_name",
    "status",
    "possible_start_date",
    "year_group"
  ];

  if (
    directusStore.isAdmin() ||
    directusStore.userHasRole("reference_person")
  ) {
    fields.push(
      "references.user_created",
      "local_church.id",
      "local_church.name"
    );
  }
  else if (directusStore.userHasRole("applicant")) {
    filter = {
      created_by: {
        id: {
          _eq: user.id
        }
      }
    };
  }

  const applications = await directus.items("applications").readByQuery({
    filter: filter,
    fields: fields,
    limit: -1
  });

  store.applications = transformApplicationsData(applications.data);

  // Get local churches from applications
  const churches = store.applications
    .filter((a) => a.local_church)
    .map((a) => {
      return { value: a.local_church.id, label: a.local_church.name };
    })
    .sort((a, b) => {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });

  // Set unique values
  store.localChurches = [
    ...new Map(churches.map((c) => [c.label, c])).values(),
  ];
  store.isLoading = false;
}

function getButtonLink(application) {
  let link = null;

  if (directusStore.userHasRole("applicant")) {
    link =
      application.status == "info"
        ? "/registration"
        : "/application/" + application.id;
  } else {
    link = "/application/" + application.id + "/reference";
  }

  router.push({
    path: link,
  });
}

function getButtonLabel(application) {
  if (directusStore.userHasRole("applicant")) {
    return application.status == "info"
      ? t("dashboard.actions.finalize")
      : t("dashboard.actions.review");
  } else {
    return application.references?.filter((r) => r.user_created == user.id)
      .length > 0
      ? t("dashboard.actions.editReference")
      : t("dashboard.actions.submit")
  }
}

function canEvaluate() {
  return (
    directusStore.isAdmin() || directusStore.userHasRole("reference_person")
  );
}

function isMultiReferencePerson() {
  return (
    canEvaluate() ||
    (directusStore.userHasRole("reference_person") &&
      user.administrator_for.length > 1)
  );
}

function getNextYearGroup() {
  const date = new Date()
  return date.getMonth()+1 >= 8
    ? `${(date.getFullYear()+1).toString().slice(-2)}/${(date.getFullYear()+2).toString().slice(-2)}`
    : `${(date.getFullYear()).toString().slice(-2)}/${(date.getFullYear()+1).toString().slice(-2)}`
}
</script>