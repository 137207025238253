import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import { router } from "./router/index.js";

import i18n from "./plugins/i18n.js";
import VueLoading from "vue-loading-overlay";
import VueAppInsights from "./helpers/vue-application-insights.js";
import { DistributedTracingModes } from "@microsoft/applicationinsights-common";
import "vue-loading-overlay/dist/vue-loading.css";
import "./main.css";

const pinia = createPinia();

const APPLICATIONINSIGHTS_INSTRUMENTATION_KEY =
  process.env.VUE_APP_APPLICATIONINSIGHTS_INSTRUMENTATION_KEY || "";

if (APPLICATIONINSIGHTS_INSTRUMENTATION_KEY) {
  let API_URL,
    APP_ENV = process.env.VUE_APP_APP_ENV;

  if (APP_ENV)
    API_URL =
      APP_ENV == "prod"
        ? "admin.ateam.bcc.no"
        : APP_ENV + ".admin.ateam.bcc.no";
  else if (process.env.NODE_ENV == "production") API_URL = "admin.ateam.bcc.no";
  else if (process.env.NODE_ENV == "development")
    API_URL = "dev.admin.ateam.bcc.no";

  createApp(App)
    .use(pinia)
    .use(VueAppInsights, {
      id: APPLICATIONINSIGHTS_INSTRUMENTATION_KEY,
      router,
      appInsightsConfig: {
        distributedTracingMode: DistributedTracingModes.W3C,
        enableCorsCorrelation: true,
        correlationHeaderDomains: [window.location.hostname, API_URL],
      },
    })
    .use(i18n)
    .use(VueLoading)
    .use(router)
    .mount("#app");
} else {
  createApp(App).use(pinia).use(i18n).use(VueLoading).use(router).mount("#app");
}
