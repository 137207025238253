<template>
    <v-select placeholder="---" v-model="$i18n.locale" :clearable="false" :reduce="option => option.value" :options="languages" class="pt-4" />
</template>

<script>
import { defineComponent } from 'vue'
import vSelect from "vue-select"

export default defineComponent({
    name: 'LanguageSwitcher',
    components: {
        "v-select": vSelect
    },

    data() {
        return {
            languages: [
                { value: 'no', label: 'Norsk' },
                { value: 'en', label: 'English' }
            ],
        }
    },

    watch: {
        '$i18n.locale': function (newLocale) {
            this.saveLocaleToLocalStorage(newLocale)
        }
    },

    methods: {
        saveLocaleToLocalStorage(locale) {
            localStorage.setItem('locale', locale)
        }
    }
})
</script>