<template>
    <section v-if="store.application" class="application grid gap-4 mb-4">
        <div class="bg-white grid gap-6 md:gap-8 rounded-lg overflow-hidden" v-if="store.application.status == 'ready_for_interview' && store.application.ateam_interview_status == 'interview_missing' && store.application.ateam_interviewer">
            <iframe :src="getAteamAssignedInterviewerLink()" style="width: 100%; height: 1000px; outline: none;" frameborder="0" scrolling="auto"></iframe>
        </div>

        <div class="bg-white grid gap-6 md:gap-8 rounded-lg overflow-hidden" v-if="store.application.status == 'ready_for_interview' && store.application.wnt_interview_status == 'interview_missing' && store.application.wnt_interviewer">
            <iframe :src="getWntAssignedInterviewerLink()" style="width: 100%; height: 1000px; outline: none;" frameborder="0" scrolling="auto"></iframe>
        </div>

        <div class="bg-white grid gap-6 md:gap-8 rounded-lg" v-if="store.application.language_comment">
            <div>
                <p class="font-bold mb-2">{{ t('languageAssessment.form.language_comment') }}</p>
                <div v-html="store.application.language_comment"></div>
            </div>
        </div>

        <form @submit.prevent="updateApplication" class="grid gap-4">
            <div class="bg-white grid md:grid-cols-2 gap-6 md:gap-8 rounded-lg">
                <h3 class="col-span-full | text-xl font-bold text-dark-green-500">{{ t('form.documents') }}</h3>

                <div :data-doc="document = getDocument('profile_picture')">
                    <div v-if="document" class="bcc-input-container w-full">
                        <span class="bcc-form-label">{{ t('form.profile_picture.title') }}</span>
                        <div class="text-dark-green-100 flex items-center gap-2 py-1.5 px-3 rounded-xl bg-neutral-100" :data-id="document.fieldName">
                            <span class="material-symbols-rounded text-4xl">{{ document.ext }}</span>
                            <div class="flex flex-col">
                                <a :href="directus._url + '/assets/' + document.assetId + '?access_token=' + store.access_token" class="font-bold transition hover:text-dark-green-500" target="_blank" :class="{ 'pointer-events-none': !document.assetId }">{{ document.tempName }}</a>
                                <span class="text-xs">{{ document.size }}</span>
                            </div>
                            <span class="material-symbols-rounded ml-auto cursor-pointer rounded-full transition hover:bg-dark-green-100 hover:text-white" @click="cancelFileUpload($event)">cancel</span>
                        </div>
                    </div>
                    <BccInput v-show="!document" class="w-full" :label="t('form.profile_picture.title')" type="file" id="profile_picture" name="profile_picture" />
                </div>

                <div :data-doc="document = getDocument('cv')">
                    <div v-if="document" class="bcc-input-container w-full">
                        <span class="bcc-form-label">{{ t('form.cv.title') }}</span>
                        <div class="text-dark-green-100 flex items-center gap-2 py-1.5 px-3 rounded-xl bg-neutral-100" :data-id="document.fieldName">
                            <span class="material-symbols-rounded text-4xl">{{ document.ext }}</span>
                            <div class="flex flex-col">
                                <a :href="directus._url + '/assets/' + document.assetId + '?access_token=' + store.access_token" class="font-bold transition hover:text-dark-green-500" target="_blank" :class="{ 'pointer-events-none': !document.assetId }">{{ document.tempName }}</a>
                                <span class="text-xs">{{ document.size }}</span>
                            </div>
                            <span class="material-symbols-rounded ml-auto cursor-pointer rounded-full transition hover:bg-dark-green-100 hover:text-white" @click="cancelFileUpload($event)">cancel</span>
                        </div>
                    </div>
                    <BccInput v-show="!document" class="w-full" :label="t('form.cv.title')" type="file" id="cv" name="cv" />
                </div>

                <div :data-doc="document = getDocument('passport_id_card')">
                    <div v-if="document" class="bcc-input-container w-full">
                        <span class="bcc-form-label">{{ t('form.passport_id_card.title') }}</span>
                        <div class="text-dark-green-100 flex items-center gap-2 py-1.5 px-3 rounded-xl bg-neutral-100" :data-id="document.fieldName">
                            <span class="material-symbols-rounded text-4xl">{{ document.ext }}</span>
                            <div class="flex flex-col">
                                <a :href="directus._url + '/assets/' + document.assetId + '?access_token=' + store.access_token" class="font-bold transition hover:text-dark-green-500" target="_blank" :class="{ 'pointer-events-none': !document.assetId }">{{ document.tempName }}</a>
                                <span class="text-xs">{{ document.size }}</span>
                            </div>
                            <span class="material-symbols-rounded ml-auto cursor-pointer rounded-full transition hover:bg-dark-green-100 hover:text-white" @click="cancelFileUpload($event)">cancel</span>
                        </div>
                    </div>
                    <BccInput v-show="!document" class="w-full" :label="t('form.passport_id_card.title')" type="file" id="passport_id_card" name="passport_id_card" />
                </div>

                <div class="bcc-input-container" :data-doc="document = getDocument('european_health_insurance_card')">
                    <div v-if="document" class="bcc-input-container w-full">
                        <span class="bcc-form-label">{{ t('form.european_health_insurance_card.title') }}</span>
                        <div class="text-dark-green-100 flex items-center gap-2 py-1.5 px-3 rounded-xl bg-neutral-100" :data-id="document.fieldName">
                            <span class="material-symbols-rounded text-4xl">{{ document.ext }}</span>
                            <div class="flex flex-col">
                                <a :href="directus._url + '/assets/' + document.assetId + '?access_token=' + store.access_token" class="font-bold transition hover:text-dark-green-500" target="_blank" :class="{ 'pointer-events-none': !document.assetId }">{{ document.tempName }}</a>
                                <span class="text-xs">{{ document.size }}</span>
                            </div>
                            <span class="material-symbols-rounded ml-auto cursor-pointer rounded-full transition hover:bg-dark-green-100 hover:text-white" @click="cancelFileUpload($event)">cancel</span>
                        </div>
                    </div>
                    <BccInput v-show="!document" class="w-full" :label="t('form.european_health_insurance_card.title')" type="file" id="european_health_insurance_card" name="european_health_insurance_card" />
                </div>

                <div :data-doc="document = getDocument('driver_license_copy')">
                    <div v-if="document" class="bcc-input-container w-full">
                        <span class="bcc-form-label">{{ t('form.driver_license_copy.title') }}</span>
                        <div class="text-dark-green-100 flex items-center gap-2 py-1.5 px-3 rounded-xl bg-neutral-100" :data-id="document.fieldName">
                            <span class="material-symbols-rounded text-4xl">{{ document.ext }}</span>
                            <div class="flex flex-col">
                                <a :href="directus._url + '/assets/' + document.assetId + '?access_token=' + store.access_token" class="font-bold transition hover:text-dark-green-500" target="_blank" :class="{ 'pointer-events-none': !document.assetId }">{{ document.tempName }}</a>
                                <span class="text-xs">{{ document.size }}</span>
                            </div>
                            <span class="material-symbols-rounded ml-auto cursor-pointer rounded-full transition hover:bg-dark-green-100 hover:text-white" @click="cancelFileUpload($event)">cancel</span>
                        </div>
                    </div>
                    <BccInput v-show="!document" class="w-full" :label="t('form.driver_license_copy.title')" type="file" id="driver_license_copy" name="driver_license_copy" />
                </div>

                <div :data-doc="document = getDocument('certificates')">
                    <div v-if="document" class="bcc-input-container w-full">
                        <span class="bcc-form-label">{{ t('form.certificates.title') }}</span>
                        <div class="text-dark-green-100 flex items-center gap-2 py-1.5 px-3 rounded-xl bg-neutral-100" :data-id="document.fieldName">
                            <span class="material-symbols-rounded text-4xl">{{ document.ext }}</span>
                            <div class="flex flex-col">
                                <a :href="directus._url + '/assets/' + document.assetId + '?access_token=' + store.access_token" class="font-bold transition hover:text-dark-green-500" target="_blank" :class="{ 'pointer-events-none': !document.assetId }">{{ document.tempName }}</a>
                                <span class="text-xs">{{ document.size }}</span>
                            </div>
                            <span class="material-symbols-rounded ml-auto cursor-pointer rounded-full transition hover:bg-dark-green-100 hover:text-white" @click="cancelFileUpload($event)">cancel</span>
                        </div>
                    </div>
                    <BccInput v-show="!document" class="w-full" :label="t('form.certificates.title')" type="file" id="certificates" name="certificates" />
                </div>

                <div :data-doc="document = getDocument('work_certificates')">
                    <div v-if="document" class="bcc-input-container w-full">
                        <span class="bcc-form-label">{{ t('form.work_certificates.title') }}</span>
                        <div class="text-dark-green-100 flex items-center gap-2 py-1.5 px-3 rounded-xl bg-neutral-100" :data-id="document.fieldName">
                            <span class="material-symbols-rounded text-4xl">{{ document.ext }}</span>
                            <div class="flex flex-col">
                                <a :href="directus._url + '/assets/' + document.assetId + '?access_token=' + store.access_token" class="font-bold transition hover:text-dark-green-500" target="_blank" :class="{ 'pointer-events-none': !document.assetId }">{{ document.tempName }}</a>
                                <span class="text-xs">{{ document.size }}</span>
                            </div>
                            <span class="material-symbols-rounded ml-auto cursor-pointer rounded-full transition hover:bg-dark-green-100 hover:text-white" @click="cancelFileUpload($event)">cancel</span>
                        </div>
                    </div>
                    <BccInput v-show="!document" class="w-full" :label="t('form.work_certificates.title')" type="file" id="work_certificates" name="work_certificates" />
                </div>

                <div :data-doc="document = getDocument('social_security_card')">
                    <div v-if="document" class="bcc-input-container w-full">
                        <span class="bcc-form-label">{{ t('form.social_security_card.title') }}</span>
                        <div class="text-dark-green-100 flex items-center gap-2 py-1.5 px-3 rounded-xl bg-neutral-100" :data-id="document.fieldName">
                            <span class="material-symbols-rounded text-4xl">{{ document.ext }}</span>
                            <div class="flex flex-col">
                                <a :href="directus._url + '/assets/' + document.assetId + '?access_token=' + store.access_token" class="font-bold transition hover:text-dark-green-500" target="_blank" :class="{ 'pointer-events-none': !document.assetId }">{{ document.tempName }}</a>
                                <span class="text-xs">{{ document.size }}</span>
                            </div>
                            <span class="material-symbols-rounded ml-auto cursor-pointer rounded-full transition hover:bg-dark-green-100 hover:text-white" @click="cancelFileUpload($event)">cancel</span>
                        </div>
                    </div>
                    <BccInput v-show="!document" class="w-full" :label="t('form.social_security_card.title')" type="file" id="social_security_card" name="social_security_card">
                        <span>{{ t('form.social_security_card.tooltip') }}</span>
                    </BccInput>
                </div>

                <div :data-doc="document = getDocument('criminal_record_check')">
                    <div v-if="document" class="bcc-input-container w-full">
                        <span class="bcc-form-label">{{ t('form.criminal_record_check.title') }}</span>
                        <div class="text-dark-green-100 flex items-center gap-2 py-1.5 px-3 rounded-xl bg-neutral-100" :data-id="document.fieldName">
                            <span class="material-symbols-rounded text-4xl">{{ document.ext }}</span>
                            <div class="flex flex-col">
                                <a :href="directus._url + '/assets/' + document.assetId + '?access_token=' + store.access_token" class="font-bold transition hover:text-dark-green-500" target="_blank" :class="{ 'pointer-events-none': !document.assetId }">{{ document.tempName }}</a>
                                <span class="text-xs">{{ document.size }}</span>
                            </div>
                            <span class="material-symbols-rounded ml-auto cursor-pointer rounded-full transition hover:bg-dark-green-100 hover:text-white" @click="cancelFileUpload($event)">cancel</span>
                        </div>
                    </div>
                    <BccInput v-show="!document" class="w-full" :label="t('form.criminal_record_check.title')" type="file" id="criminal_record_check" name="criminal_record_check" />
                </div>

                <div class="col-span-full" v-if="store.updatedDocuments.length">
                    <p class="bg-alert-50 px-4 py-2 text-alert-200 inline-flex border border-alert-100 rounded"><span class="material-symbols-rounded mr-2">sms_failed</span> {{ t('form.confirmNewDocuments') }}</p>
                </div>

                <div class="col-span-full">
                    <hr class="border-neutral-300 my-8" />
                    <BccInput :label="t('form.additional_comment')" v-model="store.additional_comment"
                        type="text" is="textarea" class="w-full" rows="4" />
                </div>

                <BccInput :label="t('form.tax_id')" v-model="store.tax_id" type="text" />
                <BccInput :label="t('form.portfolio.title')" v-model="store.portfolio" type="text">
                    <span>{{ t('form.portfolio.tooltip') }}</span>
                </BccInput>

                <BccAlert context="danger" icon v-show="store.updateFailed" class="col-span-full">{{ t('form.errors.applicationUpdateFailed') }}</BccAlert>
            </div>

            <BccButton variant="primary" type="submit" :disabled="store.formLoading || !store.updatedDocuments.length && !store.additional_comment && !store.tax_id && !store.portfolio" :class="{ 'cursor-not-allowed': store.formLoading || !store.updatedDocuments.length && !store.additional_comment && !store.tax_id && !store.portfolio }" class="col-span-full ml-auto">
                <div class="spinner" :class="{ loading: store.formLoading }"></div>
                {{ t('form.save') }}
            </BccButton>
        </form>

        <form @submit.prevent="updateKnowledgeExperienceAreas" class="grid gap-4">
            <div class="bg-white grid md:grid-cols-2 gap-6 md:gap-8 rounded-lg">
                <h3 class="col-span-full | text-xl font-bold text-dark-green-500">{{ t('form.work_experience') }}</h3>

                <div>
                    <p class="font-bold mb-1">{{ t('form.full_time_job.title') }}</p>
                    <span>{{ store.application.full_time_job ? t('form.yesOrNo.' + store.application.full_time_job) : '-' }}</span>
                </div>

                <div class="dropdown-multiple">
                    <p class="font-bold mb-1">{{ t('form.areasOfKnowledge.title') }}</p>
                    <span v-for="(value, index) in store.application.area_of_knowledge" :key="index">{{ t('form.areasOfKnowledge.' + value) }}</span>
                    <span v-if="!store.application.area_of_knowledge?.length">-</span>
                </div>

                <div class="col-span-full">
                    <p class="font-bold mb-1">{{ t('form.work_years_of_experience.title') }}</p>
                    <span>{{ store.application.work_years_of_experience || '-' }}</span>
                </div>

                <label class="bcc-form-label col-span-full">{{ t('form.work_reference_person.title') }}</label>

                <label class="bcc-form-label col-span-full">{{ t('form.work_reference_person.reference') }} 1</label>
                <BccInput :label="t('form.work_reference_person.name')" v-model="store.work_reference_person1_name" type="text" />
                <BccInput :label="t('form.work_reference_person.phone_number')" v-model="store.work_reference_person1_phone_number" type="text" />
                <BccInput :label="t('form.work_reference_person.email_address')" v-model="store.work_reference_person1_email_address" type="text" />
                <BccInput :label="t('form.work_reference_person.work_type')" v-model="store.work_reference_person1_work_type" type="text" />
               
                <label class="bcc-form-label col-span-full">{{ t('form.work_reference_person.reference') }} 2</label>
                <BccInput :label="t('form.work_reference_person.name')" v-model="store.work_reference_person2_name" type="text" />
                <BccInput :label="t('form.work_reference_person.phone_number')" v-model="store.work_reference_person2_phone_number" type="text" />
                <BccInput :label="t('form.work_reference_person.email_address')" v-model="store.work_reference_person2_email_address" type="text" />
                <BccInput :label="t('form.work_reference_person.work_type')" v-model="store.work_reference_person2_work_type" type="text" />

                <h3 class="col-span-full | text-xl font-bold text-dark-green-500">{{ t('form.knowledgeExperienceAreas.title') }}</h3>

                <div class="col-span-full">
                    <p class="font-bold">{{ t('form.knowledgeExperienceAreas.subtitle') }}</p>

                    <div class="relative col-span-full">
                        <table class="w-full text-left">
                            <thead class="sticky top-0 z-[3]">
                                <tr>
                                    <td colspan="7">
                                        <v-select multiple :placeholder="t('form.knowledgeExperienceAreas.choose_area')" :options="store.list.knowledgeExperienceAreas" v-model="store.selectedWorkArea" 
                                            :reduce="option => option.value" :selectable="option => !knowledgeExperienceAreasCategories.includes(option.value)" 
                                            @option:deselected="nullifyAreaExperience" class="mt-4 select-with-groups" menu-props="auto" />
                                    </td>
                                </tr>
                                <tr>
                                    <td class="bg-white py-1" colspan="7"></td>
                                </tr>
                                <tr v-if="store.selectedWorkArea.length > 0">
                                    <th class="py-4 px-4 min-w-[200px] sticky left-0 z-[3] bg-isbre-700 rounded-tl-lg" scope="col">{{ t('form.knowledgeExperienceAreas.area') }} / {{ t('form.knowledgeExperienceAreas.years') }}</th>
                                    <th class="py-4 px-4 min-w-[50px] bg-isbre-700 text-center" scope="col" v-for="index in 5" :key="index">{{ index }}</th>
                                    <th class="py-4 px-4 min-w-[200px] bg-isbre-700 rounded-tr-lg text-center" scope="col">{{ t('form.knowledgeExperienceAreas.interest') }}</th>
                                </tr>
                            </thead>
                            <tbody class="relative z-[1]">
                                <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.selectedWorkArea.includes('kindergarten_experience') || store.selectedWorkArea.includes('groundwork_landscaping_experience') || store.selectedWorkArea.includes('first_aid_experience') || store.selectedWorkArea.includes('car_detailing_experience') || store.selectedWorkArea.includes('stocktaking_experience') || store.selectedWorkArea.includes('pruefservice_experience') || store.selectedWorkArea.includes('cleaning_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-isbre-400 font-bold uppercase">
                                    {{ t('form.knowledgeExperienceAreas.diverse') }}</td>
                                <td v-for="idx in 6" :key="idx" class="bg-isbre-400"></td>
                                </tr>
                                <tr class="bg-white border-b border-neutral-300" v-if="store.selectedWorkArea.includes('kindergarten_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                    scope="row">{{ t('form.knowledgeExperienceAreas.kindergarten_experience') }}</td>
                                <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                    <BccRadio name="kindergarten_experience" :value="index" v-model="store.kindergarten_experience" />
                                </td>
                                <td class="py-4 px-4 text-center border-l border-neutral-300">
                                    <BccCheckbox name="kindergarten_interest" v-model="store.kindergarten_interest" />
                                </td>
                                </tr>
                                <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.selectedWorkArea.includes('groundwork_landscaping_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                    scope="row">{{ t('form.knowledgeExperienceAreas.groundwork_landscaping_experience') }}</td>
                                <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                    <BccRadio name="groundwork_landscaping_experience" :value="index"
                                    v-model="store.groundwork_landscaping_experience" />
                                </td>
                                <td class="py-4 px-4 text-center border-l border-neutral-300">
                                    <BccCheckbox name="groundwork_landscaping_interest" v-model="store.groundwork_landscaping_interest" />
                                </td>
                                </tr>
                                <tr class="bg-white border-b border-neutral-300" v-if="store.selectedWorkArea.includes('first_aid_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                    scope="row">{{ t('form.knowledgeExperienceAreas.first_aid_experience') }}
                                </td>
                                <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                    <BccRadio name="first_aid_experience" :value="index" v-model="store.first_aid_experience" />
                                </td>
                                <td class="py-4 px-4 text-center border-l border-neutral-300">
                                    <BccCheckbox name="first_aid_interest" v-model="store.first_aid_interest" />
                                </td>
                                </tr>
                                <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.selectedWorkArea.includes('car_detailing_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                    scope="row">{{ t('form.knowledgeExperienceAreas.car_detailing_experience') }}
                                </td>
                                <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                    <BccRadio name="car_detailing_experience" :value="index" v-model="store.car_detailing_experience" />
                                </td>
                                <td class="py-4 px-4 text-center border-l border-neutral-300">
                                    <BccCheckbox name="car_detailing_interest" v-model="store.car_detailing_interest" />
                                </td>
                                </tr>
                                <tr class="bg-white border-b border-neutral-300" v-if="store.selectedWorkArea.includes('stocktaking_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                    scope="row">{{ t('form.knowledgeExperienceAreas.stocktaking_experience') }}
                                </td>
                                <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                    <BccRadio name="stocktaking_experience" :value="index" v-model="store.stocktaking_experience" />
                                </td>
                                <td class="py-4 px-4 text-center border-l border-neutral-300">
                                    <BccCheckbox name="stocktaking_interest" v-model="store.stocktaking_interest" />
                                </td>
                                </tr>
                                <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.selectedWorkArea.includes('pruefservice_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                    scope="row">{{ t('form.knowledgeExperienceAreas.pruefservice_experience') }}
                                </td>
                                <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                    <BccRadio name="pruefservice_experience" :value="index" v-model="store.pruefservice_experience" />
                                </td>
                                <td class="py-4 px-4 text-center border-l border-neutral-300">
                                    <BccCheckbox name="pruefservice_interest" v-model="store.pruefservice_interest" />
                                </td>
                                </tr>
                                <tr class="bg-white border-b border-neutral-300" v-if="store.selectedWorkArea.includes('cleaning_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                    scope="row">{{ t('form.knowledgeExperienceAreas.cleaning_experience') }}
                                </td>
                                <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                    <BccRadio name="cleaning_experience" :value="index" v-model="store.cleaning_experience" />
                                </td>
                                <td class="py-4 px-4 text-center border-l border-neutral-300">
                                    <BccCheckbox name="cleaning_interest" v-model="store.cleaning_interest" />
                                </td>
                                </tr>
                                <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.selectedWorkArea.includes('accounting_experience') || store.selectedWorkArea.includes('logistics_experience') || store.selectedWorkArea.includes('event_arrangement_leadership_experience') || store.selectedWorkArea.includes('office_administration_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-isbre-400 font-bold uppercase">
                                    {{ t('form.knowledgeExperienceAreas.office_work') }}</td>
                                <td v-for="idx in 6" :key="idx" class="bg-isbre-400"></td>
                                </tr>
                                <tr class="bg-white border-b border-neutral-300" v-if="store.selectedWorkArea.includes('accounting_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                    scope="row">{{ t('form.knowledgeExperienceAreas.accounting_experience')
                                    }}</td>
                                <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                    <BccRadio name="accounting_experience" :value="index" v-model="store.accounting_experience" />
                                </td>
                                <td class="py-4 px-4 text-center border-l border-neutral-300">
                                    <BccCheckbox name="accounting_interest" v-model="store.accounting_interest" />
                                </td>
                                </tr>
                                <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.selectedWorkArea.includes('logistics_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                    scope="row">{{ t('form.knowledgeExperienceAreas.logistics_experience') }}</td>
                                <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                    <BccRadio name="logistics_experience" :value="index" v-model="store.logistics_experience" />
                                </td>
                                <td class="py-4 px-4 text-center border-l border-neutral-300">
                                    <BccCheckbox name="logistics_interest" v-model="store.logistics_interest" />
                                </td>
                                </tr>                                                
                                <tr class="bg-white border-b border-neutral-300" v-if="store.selectedWorkArea.includes('event_arrangement_leadership_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                    scope="row">{{ t('form.knowledgeExperienceAreas.event_arrangement_leadership_experience') }}</td>
                                <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                    <BccRadio name="event_arrangement_leadership_experience" :value="index" v-model="store.event_arrangement_leadership_experience" />
                                </td>
                                <td class="py-4 px-4 text-center border-l border-neutral-300">
                                    <BccCheckbox name="event_arrangement_leadership_interest" v-model="store.event_arrangement_leadership_interest" />
                                </td>
                                </tr>
                                <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.selectedWorkArea.includes('office_administration_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                    scope="row">{{ t('form.knowledgeExperienceAreas.office_administration_experience') }}</td>
                                <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                    <BccRadio name="office_administration_experience" :value="index" v-model="store.office_administration_experience" />
                                </td>
                                <td class="py-4 px-4 text-center border-l border-neutral-300">
                                    <BccCheckbox name="office_administration_interest" v-model="store.office_administration_interest" />
                                </td>
                                </tr>
                                <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.selectedWorkArea.includes('construction_installation_experience') || store.selectedWorkArea.includes('ventilation_installation_experience') || store.selectedWorkArea.includes('glass_railing_installation_experience') || store.selectedWorkArea.includes('plumbing_experience') || store.selectedWorkArea.includes('electrician_experience') || store.selectedWorkArea.includes('automatician_low_voltage_fitter_experience') || store.selectedWorkArea.includes('industrial_electricity_textile_production_experience') || store.selectedWorkArea.includes('skilled_worker_concrete_experience') || store.selectedWorkArea.includes('machine_operator_experience') || store.selectedWorkArea.includes('carpenter_woodworking_with_trade_certificate_experience') || store.selectedWorkArea.includes('carpenter_woodworking_assistant_experience') || store.selectedWorkArea.includes('cabinet_maker_experience') || store.selectedWorkArea.includes('painting_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-isbre-400 font-bold uppercase">
                                    {{ t('form.knowledgeExperienceAreas.construction') }}</td>
                                <td v-for="idx in 6" :key="idx" class="bg-isbre-400"></td>
                                </tr>
                                <tr class="bg-white border-b border-neutral-300" v-if="store.selectedWorkArea.includes('construction_installation_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                    scope="row">{{ t('form.knowledgeExperienceAreas.construction_installation_experience') }}</td>
                                <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                    <BccRadio name="construction_installation_experience" :value="index" v-model="store.construction_installation_experience" />
                                </td>
                                <td class="py-4 px-4 text-center border-l border-neutral-300">
                                    <BccCheckbox name="construction_installation_interest" v-model="store.construction_installation_interest" />
                                </td>
                                </tr>
                                <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.selectedWorkArea.includes('ventilation_installation_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                    scope="row">{{ t('form.knowledgeExperienceAreas.ventilation_installation_experience') }}</td>
                                <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                    <BccRadio name="ventilation_installation_experience" :value="index" v-model="store.ventilation_installation_experience" />
                                </td>
                                <td class="py-4 px-4 text-center border-l border-neutral-300">
                                    <BccCheckbox name="ventilation_installation_interest" v-model="store.ventilation_installation_interest" />
                                </td>
                                </tr>
                                <tr class="bg-white border-b border-neutral-300" v-if="store.selectedWorkArea.includes('glass_railing_installation_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                    scope="row">{{ t('form.knowledgeExperienceAreas.glass_railing_installation_experience') }}</td>
                                <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                    <BccRadio name="glass_railing_installation_experience" :value="index" v-model="store.glass_railing_installation_experience" />
                                </td>
                                <td class="py-4 px-4 text-center border-l border-neutral-300">
                                    <BccCheckbox name="glass_railing_installation_interest" v-model="store.glass_railing_installation_interest" />
                                </td>
                                </tr>
                                <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.selectedWorkArea.includes('plumbing_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                    scope="row">{{ t('form.knowledgeExperienceAreas.plumbing_experience') }}</td>
                                <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                    <BccRadio name="plumbing_experience" :value="index" v-model="store.plumbing_experience" />
                                </td>
                                <td class="py-4 px-4 text-center border-l border-neutral-300">
                                    <BccCheckbox name="plumbing_interest" v-model="store.plumbing_interest" />
                                </td>
                                </tr>
                                <tr class="bg-white border-b border-neutral-300" v-if="store.selectedWorkArea.includes('electrician_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                    scope="row">{{ t('form.knowledgeExperienceAreas.electrician_experience') }}</td>
                                <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                    <BccRadio name="electrician_experience" :value="index" v-model="store.electrician_experience" />
                                </td>
                                <td class="py-4 px-4 text-center border-l border-neutral-300">
                                    <BccCheckbox name="electrician_interest" v-model="store.electrician_interest" />
                                </td>
                                </tr>
                                <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.selectedWorkArea.includes('automatician_low_voltage_fitter_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                    scope="row">{{ t('form.knowledgeExperienceAreas.automatician_low_voltage_fitter_experience') }}</td>
                                <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                    <BccRadio name="automatician_low_voltage_fitter_experience" :value="index" v-model="store.automatician_low_voltage_fitter_experience" />
                                </td>
                                <td class="py-4 px-4 text-center border-l border-neutral-300">
                                    <BccCheckbox name="automatician_low_voltage_fitter_interest" v-model="store.automatician_low_voltage_fitter_interest" />
                                </td>
                                </tr>
                                <tr class="bg-white border-b border-neutral-300" v-if="store.selectedWorkArea.includes('industrial_electricity_textile_production_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                    scope="row">{{ t('form.knowledgeExperienceAreas.industrial_electricity_textile_production_experience') }}</td>
                                <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                    <BccRadio name="industrial_electricity_textile_production_experience" :value="index" v-model="store.industrial_electricity_textile_production_experience" />
                                </td>
                                <td class="py-4 px-4 text-center border-l border-neutral-300">
                                    <BccCheckbox name="industrial_electricity_textile_production_interest" v-model="store.industrial_electricity_textile_production_interest" />
                                </td>
                                </tr>
                                <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.selectedWorkArea.includes('skilled_worker_concrete_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                    scope="row">{{ t('form.knowledgeExperienceAreas.skilled_worker_concrete_experience') }}</td>
                                <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                    <BccRadio name="skilled_worker_concrete_experience" :value="index" v-model="store.skilled_worker_concrete_experience" />
                                </td>
                                <td class="py-4 px-4 text-center border-l border-neutral-300">
                                    <BccCheckbox name="skilled_worker_concrete_interest" v-model="store.skilled_worker_concrete_interest" />
                                </td>
                                </tr>
                                <tr class="bg-white border-b border-neutral-300" v-if="store.selectedWorkArea.includes('machine_operator_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                    scope="row">{{ t('form.knowledgeExperienceAreas.machine_operator_experience') }}</td>
                                <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                    <BccRadio name="machine_operator_experience" :value="index" v-model="store.machine_operator_experience" />
                                </td>
                                <td class="py-4 px-4 text-center border-l border-neutral-300">
                                    <BccCheckbox name="machine_operator_interest" v-model="store.machine_operator_interest" />
                                </td>
                                </tr>
                                <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.selectedWorkArea.includes('carpenter_woodworking_with_trade_certificate_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                    scope="row">{{ t('form.knowledgeExperienceAreas.carpenter_woodworking_with_trade_certificate_experience') }}</td>
                                <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                    <BccRadio name="carpenter_woodworking_with_trade_certificate_experience" :value="index" v-model="store.carpenter_woodworking_with_trade_certificate_experience" />
                                </td>
                                <td class="py-4 px-4 text-center border-l border-neutral-300">
                                    <BccCheckbox name="carpenter_woodworking_with_trade_certificate_interest" v-model="store.carpenter_woodworking_with_trade_certificate_interest" />
                                </td>
                                </tr>
                                <tr class="bg-white border-b border-neutral-300" v-if="store.selectedWorkArea.includes('carpenter_woodworking_assistant_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                    scope="row">{{ t('form.knowledgeExperienceAreas.carpenter_woodworking_assistant_experience') }}</td>
                                <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                    <BccRadio name="carpenter_woodworking_assistant_experience" :value="index" v-model="store.carpenter_woodworking_assistant_experience" />
                                </td>
                                <td class="py-4 px-4 text-center border-l border-neutral-300">
                                    <BccCheckbox name="carpenter_woodworking_assistant_interest" v-model="store.carpenter_woodworking_assistant_interest" />
                                </td>
                                </tr>
                                <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.selectedWorkArea.includes('cabinet_maker_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                    scope="row">{{ t('form.knowledgeExperienceAreas.cabinet_maker_experience') }}</td>
                                <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                    <BccRadio name="cabinet_maker_experience" :value="index" v-model="store.cabinet_maker_experience" />
                                </td>
                                <td class="py-4 px-4 text-center border-l border-neutral-300">
                                    <BccCheckbox name="cabinet_maker_interest" v-model="store.cabinet_maker_interest" />
                                </td>
                                </tr>
                                <tr class="bg-white border-b border-neutral-300" v-if="store.selectedWorkArea.includes('painting_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                    scope="row">{{ t('form.knowledgeExperienceAreas.painting_experience') }}</td>
                                <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                    <BccRadio name="painting_experience" :value="index" v-model="store.painting_experience" />
                                </td>
                                <td class="py-4 px-4 text-center border-l border-neutral-300">
                                    <BccCheckbox name="painting_interest" v-model="store.painting_interest" />
                                </td>
                                </tr>
                                <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.selectedWorkArea.includes('cooking_experience') || store.selectedWorkArea.includes('waiter_catering_experience') || store.selectedWorkArea.includes('production_of_bakery_products_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-isbre-400 font-bold uppercase">
                                    {{ t('form.knowledgeExperienceAreas.cooking') }}</td>
                                <td v-for="idx in 6" :key="idx" class="bg-isbre-400"></td>
                                </tr>
                                <tr class="bg-white border-b border-neutral-300" v-if="store.selectedWorkArea.includes('cooking_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                    scope="row">{{ t('form.knowledgeExperienceAreas.cooking_experience') }}</td>
                                <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                    <BccRadio name="cooking_experience" :value="index" v-model="store.cooking_experience" />
                                </td>
                                <td class="py-4 px-4 text-center border-l border-neutral-300">
                                    <BccCheckbox name="cooking_interest" v-model="store.cooking_interest" />
                                </td>
                                </tr>
                                <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.selectedWorkArea.includes('waiter_catering_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                    scope="row">{{ t('form.knowledgeExperienceAreas.waiter_catering_experience') }}</td>
                                <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                    <BccRadio name="waiter_catering_experience" :value="index" v-model="store.waiter_catering_experience" />
                                </td>
                                <td class="py-4 px-4 text-center border-l border-neutral-300">
                                    <BccCheckbox name="waiter_catering_interest" v-model="store.waiter_catering_interest" />
                                </td>
                                </tr>
                                <tr class="bg-white border-b border-neutral-300" v-if="store.selectedWorkArea.includes('production_of_bakery_products_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                    scope="row">{{ t('form.knowledgeExperienceAreas.production_of_bakery_products_experience') }}</td>
                                <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                    <BccRadio name="production_of_bakery_products_experience" :value="index" v-model="store.production_of_bakery_products_experience" />
                                </td>
                                <td class="py-4 px-4 text-center border-l border-neutral-300">
                                    <BccCheckbox name="production_of_bakery_products_interest" v-model="store.production_of_bakery_products_interest" />
                                </td>
                                </tr>
                                <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.selectedWorkArea.includes('composing_experience') || store.selectedWorkArea.includes('electronic_music_experience') || store.selectedWorkArea.includes('other_music_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-isbre-400 font-bold uppercase">
                                    {{ t('form.knowledgeExperienceAreas.music') }}</td>
                                <td v-for="idx in 6" :key="idx" class="bg-isbre-400"></td>
                                </tr>
                                <tr class="bg-white border-b border-neutral-300" v-if="store.selectedWorkArea.includes('composing_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                    scope="row">{{ t('form.knowledgeExperienceAreas.composing_experience') }}
                                </td>
                                <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                    <BccRadio name="composing_experience" :value="index" v-model="store.composing_experience" />
                                </td>
                                <td class="py-4 px-4 text-center border-l border-neutral-300">
                                    <BccCheckbox name="composing_interest" v-model="store.composing_interest" />
                                    </td>
                                </tr>
                                <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.selectedWorkArea.includes('electronic_music_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                    scope="row">{{ t('form.knowledgeExperienceAreas.electronic_music_experience') }}
                                </td>
                                <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                    <BccRadio name="electronic_music_experience" :value="index" v-model="store.electronic_music_experience" />
                                </td>
                                <td class="py-4 px-4 text-center border-l border-neutral-300">
                                    <BccCheckbox name="electronic_music_interest" v-model="store.electronic_music_interest" />
                                </td>
                                </tr>
                                <tr class="bg-white border-b border-neutral-300" v-if="store.selectedWorkArea.includes('other_music_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                    scope="row">{{ t('form.knowledgeExperienceAreas.other_music_experience') }}
                                </td>
                                <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                    <BccRadio name="other_music_experience" :value="index" v-model="store.other_music_experience" />
                                </td>
                                <td class="py-4 px-4 text-center border-l border-neutral-300">
                                    <BccCheckbox name="other_music_interest" v-model="store.other_music_interest" />
                                </td>
                                </tr>
                                <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.selectedWorkArea.includes('helpdesk_experience') || store.selectedWorkArea.includes('software_development_experience') || store.selectedWorkArea.includes('ux_designer_experience') || store.selectedWorkArea.includes('websites_experience') || store.selectedWorkArea.includes('other_it_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-isbre-400 font-bold uppercase">
                                    {{ t('form.knowledgeExperienceAreas.it') }}
                                </td>
                                <td v-for="idx in 6" :key="idx" class="bg-isbre-400"></td>
                                </tr>
                                <tr class="bg-white border-b border-neutral-300" v-if="store.selectedWorkArea.includes('helpdesk_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                    scope="row">{{ t('form.knowledgeExperienceAreas.helpdesk_experience') }}
                                </td>
                                <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                    <BccRadio name="helpdesk_experience" :value="index" v-model="store.helpdesk_experience" />
                                </td>
                                <td class="py-4 px-4 text-center border-l border-neutral-300">
                                    <BccCheckbox name="helpdesk_interest" v-model="store.helpdesk_interest" />
                                </td>
                                </tr>
                                <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.selectedWorkArea.includes('software_development_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                scope="row">{{ t('form.knowledgeExperienceAreas.software_development_experience') }}</td>
                                <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                    <BccRadio name="software_development_experience" :value="index" v-model="store.software_development_experience" />
                                </td>
                                <td class="py-4 px-4 text-center border-l border-neutral-300">
                                    <BccCheckbox name="software_development_interest" v-model="store.software_development_interest" />
                                </td>
                                </tr>
                                <tr class="bg-white border-b border-neutral-300" v-if="store.selectedWorkArea.includes('ux_designer_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                scope="row">{{ t('form.knowledgeExperienceAreas.ux_designer_experience') }}</td>
                                <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                    <BccRadio name="ux_designer_experience" :value="index" v-model="store.ux_designer_experience" />
                                </td>
                                <td class="py-4 px-4 text-center border-l border-neutral-300">
                                    <BccCheckbox name="ux_designer_interest" v-model="store.ux_designer_interest" />
                                </td>
                                </tr>
                                <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.selectedWorkArea.includes('websites_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                    scope="row">{{ t('form.knowledgeExperienceAreas.websites_experience') }}
                                </td>
                                <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                    <BccRadio name="websites_experience" :value="index" v-model="store.websites_experience" />
                                </td>
                                <td class="py-4 px-4 text-center border-l border-neutral-300">
                                    <BccCheckbox name="websites_interest" v-model="store.websites_interest" />
                                    </td>
                                </tr>
                                <tr class="bg-white border-b border-neutral-300" v-if="store.selectedWorkArea.includes('other_it_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                scope="row">{{ t('form.knowledgeExperienceAreas.other_it_experience') }}
                                </td>
                                <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                    <BccRadio name="other_it_experience" :value="index" v-model="store.other_it_experience" />
                                </td>
                                <td class="py-4 px-4 text-center border-l border-neutral-300">
                                    <BccCheckbox name="other_it_interest" v-model="store.other_it_interest" />
                                    </td>
                                </tr>
                                <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.selectedWorkArea.includes('design_experience') || store.selectedWorkArea.includes('film_production_experience') || store.selectedWorkArea.includes('film_editing_experience') || store.selectedWorkArea.includes('animation_experience') || store.selectedWorkArea.includes('other_media_film_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-isbre-400 font-bold uppercase">
                                    {{ t('form.knowledgeExperienceAreas.media_film') }}</td>
                                <td v-for="idx in 6" :key="idx" class="bg-isbre-400"></td>
                                </tr>
                                <tr class="bg-white border-b border-neutral-300" v-if="store.selectedWorkArea.includes('design_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                    scope="row">{{ t('form.knowledgeExperienceAreas.design_experience') }}</td>
                                <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                    <BccRadio name="design_experience" :value="index" v-model="store.design_experience" />
                                </td>
                                <td class="py-4 px-4 text-center border-l border-neutral-300">
                                    <BccCheckbox name="design_interest" v-model="store.design_interest" />
                                </td>
                                </tr>
                                <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.selectedWorkArea.includes('film_production_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                scope="row">{{ t('form.knowledgeExperienceAreas.film_production_experience') }}</td>
                                <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                    <BccRadio name="film_production_experience" :value="index" v-model="store.film_production_experience" />
                                </td>
                                <td class="py-4 px-4 text-center border-l border-neutral-300">
                                    <BccCheckbox name="film_production_interest" v-model="store.film_production_interest" />
                                </td>
                                </tr>
                                <tr class="bg-white border-b border-neutral-300" v-if="store.selectedWorkArea.includes('film_editing_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                scope="row">{{ t('form.knowledgeExperienceAreas.film_editing_experience') }}
                                </td>
                                <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                    <BccRadio name="film_editing_experience" :value="index" v-model="store.film_editing_experience" />
                                </td>
                                <td class="py-4 px-4 text-center border-l border-neutral-300">
                                    <BccCheckbox name="film_editing_interest" v-model="store.film_editing_interest" />
                                    </td>
                                </tr>
                                <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.selectedWorkArea.includes('animation_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                scope="row">{{ t('form.knowledgeExperienceAreas.animation_experience') }}
                                </td>
                                <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                    <BccRadio name="animation_experience" :value="index" v-model="store.animation_experience" />
                                </td>
                                <td class="py-4 px-4 text-center border-l border-neutral-300">
                                    <BccCheckbox name="animation_interest" v-model="store.animation_interest" />
                                    </td>
                                </tr>
                                <tr class="bg-white border-b border-neutral-300" v-if="store.selectedWorkArea.includes('other_media_film_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                scope="row">{{ t('form.knowledgeExperienceAreas.other_media_film_experience') }}
                                </td>
                                <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                    <BccRadio name="other_media_film_experience" :value="index" v-model="store.other_media_film_experience" />
                                </td>
                                <td class="py-4 px-4 text-center border-l border-neutral-300">
                                    <BccCheckbox name="other_media_film_interest" v-model="store.other_media_film_interest" />
                                    </td>
                                </tr>
                                <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.selectedWorkArea.includes('sound_experience') || store.selectedWorkArea.includes('light_experience') || store.selectedWorkArea.includes('graphics_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-isbre-400 font-bold uppercase">
                                    {{ t('form.knowledgeExperienceAreas.llb') }}</td>
                                <td v-for="idx in 6" :key="idx" class="bg-isbre-400"></td>
                                </tr>
                                <tr class="bg-white border-b border-neutral-300" v-if="store.selectedWorkArea.includes('sound_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                    scope="row">{{ t('form.knowledgeExperienceAreas.sound_experience') }}
                                </td>
                                <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                    <BccRadio name="sound_experience" :value="index" v-model="store.sound_experience" />
                                </td>
                                <td class="py-4 px-4 text-center border-l border-neutral-300">
                                    <BccCheckbox name="sound_interest" v-model="store.sound_interest" />
                                </td>
                                </tr>
                                <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.selectedWorkArea.includes('light_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                    scope="row">{{ t('form.knowledgeExperienceAreas.light_experience') }}</td>
                                <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                    <BccRadio name="light_experience" :value="index" v-model="store.light_experience" />
                                </td>
                                <td class="py-4 px-4 text-center border-l border-neutral-300">
                                    <BccCheckbox name=" light_interest" v-model="store.light_interest" />
                                </td>
                                </tr>
                                <tr class="bg-white border-b border-neutral-300" v-if="store.selectedWorkArea.includes('graphics_experience')">
                                <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                    scope="row">{{ t('form.knowledgeExperienceAreas.graphics_experience') }}
                                </td>
                                <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                    <BccRadio name="graphics_experience" :value="index" v-model="store.graphics_experience" />
                                </td>
                                <td class="py-4 px-4 text-center border-l border-neutral-300">
                                    <BccCheckbox name="graphics_interest" v-model="store.graphics_interest" />
                                </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <BccAlert context="danger" icon v-show="store.updateFailed" class="col-span-full">{{ t('form.errors.applicationUpdateFailed') }}</BccAlert>

                <BccButton variant="primary" type="submit" :disabled="store.formLoading" class="col-span-full ml-auto" :class="{ 'cursor-not-allowed': store.formLoading }">
                    <div class="spinner" :class="{ loading: store.formLoading }"></div>
                    {{ t('form.save') }}
                </BccButton>
            </div>
        </form>

        <div class="bg-white grid md:grid-cols-2 gap-6 md:gap-8 mt-4 rounded-lg">
            <h3 class="col-span-full | text-xl font-bold text-dark-green-500">{{ t('form.work_life_experience') }}</h3>

            <div>
                <p class="font-bold mb-1">{{ t('form.msOfficeKnowledge.title') }}</p>
                <span>{{ store.application.ms_office_knowledge ? t('form.msOfficeKnowledge.' + store.application.ms_office_knowledge) : '-' }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.other_knowledge.title') }}</p>
                <span>{{ store.application.other_knowledge || '-' }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.military_experience.title') }}</p>
                <span>{{ store.application.military_experience ? t('form.yesOrNo.' + store.application.military_experience) : '-' }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.military_remarks.title') }}</p>
                <span>{{ store.application.military_remarks || '-' }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.international_experience_longer_than_3_months.title') }}</p>
                <span>{{ t('form.yesOrNo.' + store.application.international_experience_longer_than_3_months) }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.international_experience_comment.title') }}</p>
                <span>{{ store.application.international_experience_comment || '-' }}</span>
            </div>

            <div class="dropdown-multiple">
                <p class="font-bold mb-1">{{ t('form.volunteerExperience.title') }}</p>
                <span v-for="(value, index) in store.application.volunteer_experience" :key="index">{{ t('form.volunteerExperience.' + value) }}</span>
                <span v-if="!store.application.volunteer_experience?.length">-</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.volunteer_experience_other_remarks.title') }}</p>
                <span>{{ store.application.volunteer_experience_other_remarks || '-' }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.lived_alone_shared_apartment.title') }}</p>
                <span>{{ t('form.yesOrNo.' + store.application.lived_alone_shared_apartment) }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.leadershipResponsibilities.title') }}</p>
                <span>{{ store.application.leadership_responsibilities ? t('form.leadershipResponsibilities.' + store.application.leadership_responsibilities) : '-' }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.motivation_as_team_leader.title') }}</p>
                <span>{{ t('form.yesOrNo.' + store.application.motivation_as_team_leader) }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.motivation_as_team_leader_comment.title') }}</p>
                <span>{{ store.application.motivation_as_team_leader_comment || '-' }}</span>
            </div>
        </div>

        <div class="bg-white grid md:grid-cols-2 gap-2 md:gap-4 mt-4 rounded-lg">
            <h3 class="col-span-full | text-xl font-bold text-dark-green-500">{{ t('form.general_info.title') }}</h3>

            <div class="col-span-full">
                <p class="font-bold mb-1">{{ t('form.gender.title') }}</p>
                <span>{{ t('form.gender.' + store.application.gender) }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.first_name.title') }}</p>
                <span>{{ store.application.first_name }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.last_name.title') }}</p>
                <span>{{ store.application.last_name }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.address.title') }}</p>
                <span>{{ store.application.address }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.postal_code.title') }}</p>
                <span>{{ store.application.postal_code }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.city.title') }}</p>
                <span>{{ store.application.city }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.country.title') }}</p>
                <span>{{ store.application.country }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.birth_date.title') }}</p>
                <span>{{ getFormattedDate(store.application.birth_date) }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.birth_place.title') }}</p>
                <span>{{ store.application.birth_place }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.country_code.title') }}</p>
                <span>{{ store.application.country_code }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.phone_number.title') }}</p>
                <span>{{ store.application.phone_number }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.email_address.title') }}</p>
                <span>{{ store.application.email_address }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('dashboard.local_church') }}</p>
                <span>{{ store.application.local_church?.name }}</span>
            </div>

            <hr class="col-span-full | border-neutral-300 my-8" />
            <h3 class="col-span-full | text-xl font-bold text-dark-green-500">{{ t('form.participation') }}</h3>

            <div class="col-span-full">
                <p class="font-bold mb-1">{{ t('form.possible_start_date.title') }}</p>
                <span>{{ getFormattedDate(store.application.possible_start_date) }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.former_participant.title') }}</p>
                <span>{{ t('form.yesOrNo.' + store.application.former_participant) }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.former_participation_date.title') }}</p>
                <span>{{ store.application.former_participation_date || '-' }}</span>
            </div>

            <div class="col-span-full">
                <p class="font-bold mb-1">{{ t('form.preferred_location_comment.title') }}</p>
                <span>{{ store.application.preferred_location_comment ? store.application.preferred_location_comment : '-' }}</span>
            </div>

            <hr class="col-span-full | border-neutral-300 my-8" />
            <h3 class="col-span-full | text-xl font-bold text-dark-green-500">{{ t('form.travel_info.title') }}</h3>

            <div>
                <p class="font-bold mb-1">{{ t('form.citizenship.title') }}</p>
                <span v-for="(value, index) in store.application.citizenship" :key="index">{{ t('form.citizenship.' + value) }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.passport_first_name.title') }}</p>
                <span>{{ store.application.passport_first_name || '-' }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.passport_last_name.title') }}</p>
                <span>{{ store.application.passport_last_name || '-' }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.passport_issue_date.title') }}</p>
                <span>{{ store.application.passport_issue_date ? getFormattedDate(store.application.passport_issue_date) : '-' }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.passport_issue_place.title') }}</p>
                <span>{{ store.application.passport_issue_place || '-' }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.passport_valability.title') }}</p>
                <span>{{ store.application.passport_valability ? getFormattedDate(store.application.passport_valability) : '-' }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.passport_number.title') }}</p>
                <span>{{ store.application.passport_number || '-' }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.double_citizenship.title') }}</p>
                <span v-for="(value, index) in store.application.double_citizenship" :key="index">{{ t('form.citizenship.' + value) }}</span>
            </div>

            <div class="col-span-full">
                <p class="font-bold mb-1">{{ t('form.norwegian_personal_number.title') }}</p>
                <span>{{ store.application.norwegian_personal_number || '-' }}</span>
            </div>

            <hr class="col-span-full | border-neutral-300 my-8" />
            <h3 class="col-span-full | text-xl font-bold text-dark-green-500">{{ t('form.education') }}</h3>

            <div>
                <p class="font-bold mb-1">{{ t('form.school_level.title') }}</p>
                <span>{{ t('form.schoolLevels.' + store.application.school_level) }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.school_level_on_start.title') }}</p>
                <span>{{ t('form.schoolLevels.' + store.application.school_level_on_start) }}</span>
            </div>

            <hr class="col-span-full | border-neutral-300 my-8" />
            <h3 class="col-span-full | text-xl font-bold text-dark-green-500">{{ t('form.vocational_training.title') }}</h3>

            <div class="col-span-full">
                <p class="font-bold mb-1">{{ t('form.vocational_training_status.title') }}</p>
                <span>{{ store.application.vocational_training_status ? t('form.educationStatus.' + store.application.vocational_training_status) : '-' }}</span>
            </div>

            <div class="dropdown-multiple">
                <p class="font-bold mb-1">{{ t('form.vocational_training_started.title') }}</p>
                <span v-for="(value, index) in store.application.vocational_training_started" :key="index">{{ t('form.vocationalTrainingAreas.' + value) }}</span>
                <span v-if="!store.application.vocational_training_started">-</span>
            </div>

            <div class="dropdown-multiple">
                <p class="font-bold mb-1">{{ t('form.vocational_training_will_complete.title') }}</p>
                <span v-for="(value, index) in store.application.vocational_training_will_complete" :key="index">{{ t('form.vocationalTrainingAreas.' + value) }}</span>
                <span v-if="!store.application.vocational_training_will_complete">-</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.vocational_training_comment.title') }}</p>
                <span>{{ store.application.vocational_training_comment || '-' }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.vocational_training_certified_date.title') }}</p>
                <span>{{ store.application.vocational_training_certified_date || '-' }}</span>
            </div>

            <hr class="col-span-full | border-neutral-300 my-8" />
            <h3 class="col-span-full | text-xl font-bold text-dark-green-500">{{ t('form.studies') }}</h3>

            <div class="col-span-full">
                <p class="font-bold mb-1">{{ t('form.studies_status.title') }}</p>
                <span>{{ store.application.studies_status ? t('form.educationStatus.' + store.application.studies_status) : '-' }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.study_started_title.title') }}</p>
                <span>{{ store.application.study_started_title || '-' }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.study_title.title') }}</p>
                <span>{{ store.application.study_title || '-' }}</span>
            </div>

            <hr class="col-span-full | border-neutral-300 my-8" />
            <h3 class="col-span-full | text-xl font-bold text-dark-green-500">{{ t('form.driving') }}</h3>

            <div>
                <p class="font-bold mb-1">{{ t('form.driverLicense.title') }}</p>
                <span>{{ t('form.driverLicense.' + store.application.driver_license) }}</span>
            </div>

            <div class="dropdown-multiple">
                <p class="font-bold mb-1">{{ t('form.driverLicenseClasses.title') }}</p>
                <span v-for="(value, index) in store.application.driver_license_class" :key="index">{{ t('form.driverLicenseClasses.' + value) }}</span>
                <span v-if="!store.application.driver_license_class">-</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.driver_license_since.title') }}</p>
                <span>{{ store.application.driver_license_since ? getFormattedDate(store.application.driver_license_since) : '-' }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.driver_license_comment.title') }}</p>
                <span>{{ store.application.driver_license_comment ? store.application.driver_license_comment : '-' }}</span>
            </div>

            <hr class="col-span-full | border-neutral-300 my-8" />
            <h3 class="col-span-full | text-xl font-bold text-dark-green-500">{{ t('form.work_equipment') }}</h3>

            <div>
                <p class="font-bold mb-1">{{ t('form.shoesSizes.title') }}</p>
                <span>{{ store.application.shoe_size ? t('form.shoesSizes.' + store.application.shoe_size) : '-' }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.pants_size.title') }}</p>
                <span class="uppercase">{{ store.application.pants_size || '-' }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.tshirt_size.title') }}</p>
                <span class="uppercase">{{ store.application.tshirt_size || '-' }}</span>
            </div>

            <hr class="col-span-full | border-neutral-300 my-8" />
            <h3 class="col-span-full | text-xl font-bold text-dark-green-500">{{ t('form.communication') }}</h3>

            <div>
                <p class="font-bold mb-1">{{ t('form.preferredCommunicationLanguages.title') }}</p>
                <span>{{ t('form.preferredCommunicationLanguages.' + store.application.preferred_communication_language) }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.norwegian_language_level.title') }}</p>
                <span>{{ t('form.languageLevels.' + store.application.norwegian_language_level) }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.german_language_level.title') }}</p>
                <span>{{ t('form.languageLevels.' + store.application.german_language_level) }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.english_language_level.title') }}</p>
                <span>{{ t('form.languageLevels.' + store.application.english_language_level) }}</span>
            </div>

            <div class="dropdown-multiple">
                <p class="font-bold mb-1">{{ t('form.languages.title') }}</p>
                <span v-for="(value, index) in store.application.other_languages" :key="index">{{ t('form.languages.' + value) }}</span>
                <span v-if="!store.application.other_languages">-</span>
            </div>

            <hr class="col-span-full | border-neutral-300 my-8" />
            <h3 class="col-span-full | text-xl font-bold text-dark-green-500">{{ t('form.emergency_contact') }}</h3>

            <div>
                <p class="font-bold mb-1">{{ t('form.relative_name.title') }}</p>
                <span>{{ store.application.relative_name || '-' }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.relative_email_address.title') }}</p>
                <span>{{ store.application.relative_email_address || '-' }}</span>
            </div>

            <div class="col-span-full">
                <p class="font-bold mb-1">{{ t('form.relative_address.title') }}</p>
                <span>{{ store.application.relative_address || '-' }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.relative_country_code.title') }}</p>
                <span>{{ store.application.relative_country_code || '-' }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.relative_phone_number.title') }}</p>
                <span>{{ store.application.relative_phone_number || '-' }}</span>
            </div>

            <hr class="col-span-full | border-neutral-300 my-8" />
            <h3 class="col-span-full | text-xl font-bold text-dark-green-500">{{ t('form.health') }}</h3>

            <div>
                <p class="font-bold mb-1">{{ t('form.healthy.title') }}</p>
                <span>{{ t('form.yesOrNo.' + store.application.healthy) }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.can_work_full_time.title') }}</p>
                <span>{{ t('form.yesOrNo.' + store.application.can_work_full_time) }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.health_information.title') }}</p>
                <span>{{ store.application.health_information || '-' }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.food_intolerance.title') }}</p>
                <span>{{ store.application.food_intolerance || '-' }}</span>
            </div>

            <hr class="col-span-full | border-neutral-300 my-8" />
            <h3 class="col-span-full | text-xl font-bold text-dark-green-500">{{ t('form.personal_info') }}</h3>

            <div>
                <p class="font-bold mb-1">{{ t('form.motivation_and_expectation.title') }}</p>
                <span>{{ store.application.motivation_and_expectation || '-' }}</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.personal_description.title') }}</p>
                <span>{{ store.application.personal_description || '-' }}</span>
            </div>

            <div class="dropdown-multiple">
                <p class="font-bold mb-1">{{ t('form.hobbies.title') }}</p>
                <span v-for="(value, index) in store.application.hobbies" :key="index">{{ t('form.hobbies.' + value) }}</span>
                <span v-if="!store.application.hobbies">-</span>
            </div>

            <div>
                <p class="font-bold mb-1">{{ t('form.preferable_tasks_in_local_church.title') }}</p>
                <span>{{ store.application.preferable_tasks_in_local_church || '-' }}</span>
            </div>
        </div>
    </section>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router'
import { onBeforeMount, reactive, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useDirectusStore } from '../stores/directus'
import { useAuthStore } from '../stores/auth'
import { getFileSize, getFileExtension, getFileName } from '../helpers/files.js'
import { getFormattedDate } from '../helpers/fields.js'
import { BccButton, BccInput, BccCheckbox, BccRadio, BccAlert } from '@bcc-code/design-library-vue'

import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"

const { t, locale } = useI18n({
    inheritLocale: true,
    useScope: 'local'
})

const route = useRoute()
const router = useRouter()
const directusStore = useDirectusStore()
const directus = directusStore.directus
const auth = useAuthStore()

let selectOptionsKeysTranslatable = {
    knowledgeExperienceAreas: [
        'diverse',
        'kindergarten_experience',
        'groundwork_landscaping_experience',
        'first_aid_experience',
        'car_detailing_experience',
        'stocktaking_experience',
        'pruefservice_experience',
        'cleaning_experience',
        'office_work',
        'accounting_experience',
        'logistics_experience',
        'event_arrangement_leadership_experience',
        'office_administration_experience',
        'construction',
        'construction_installation_experience',
        'ventilation_installation_experience',
        'glass_railing_installation_experience',
        'plumbing_experience',
        'electrician_experience',
        'automatician_low_voltage_fitter_experience',
        'industrial_electricity_textile_production_experience',
        'skilled_worker_concrete_experience',
        'machine_operator_experience',
        'carpenter_woodworking_with_trade_certificate_experience',
        'carpenter_woodworking_assistant_experience',
        'cabinet_maker_experience',
        'painting_experience',
        'cooking',
        'cooking_experience',
        'waiter_catering_experience',
        'production_of_bakery_products_experience',
        'music',
        'composing_experience',
        'electronic_music_experience',
        'other_music_experience',
        'it',
        'helpdesk_experience',
        'software_development_experience',
        'ux_designer_experience',
        'websites_experience',
        'other_it_experience',
        'media_film',
        'design_experience',
        'film_production_experience',
        'film_editing_experience',
        'animation_experience',
        'other_media_film_experience',
        'llb',
        'sound_experience',
        'light_experience',
        'graphics_experience'
  ]
}

let selectOptionsList = {}

// Translatable values
Object.entries(selectOptionsKeysTranslatable).map(([key, value]) => {
  selectOptionsList[key] = value.map(val => ({ 'value': val, 'label': t('form.' + key + '.' + val) }))
})

const knowledgeExperienceAreasCategories = [
  'diverse',
  'office_work',
  'construction',
  'cooking',
  'music',
  'it',
  'media_film',
  'llb'
]

watch(
  locale,
  () => updateSelectOptionsLists()
)

function updateSelectOptionsLists() {
  Object.entries(store.selectOptionsKeysTranslatable).map(([key, value]) => {
    store.list[key] = value.map(val => ({ 'value': val, 'label': t('form.' + key + '.' + val) }))
  })
}

const store = reactive({
    access_token: null,
    application: null,
    updatedDocuments: [],

    selectOptionsKeysTranslatable: selectOptionsKeysTranslatable,
    list: selectOptionsList,
    selectedWorkArea: [],

    updateFailed: false,
    formLoading: false,

    files: [],
    additional_comment: "",
    tax_id: "",
    portfolio: "",

    work_reference_person1_name: null,
    work_reference_person1_phone_number: null,
    work_reference_person1_email_address: null,
    work_reference_person1_work_type: null,
    work_reference_person2_name: null,
    work_reference_person2_phone_number: null,
    work_reference_person2_email_address: null,
    work_reference_person2_work_type: null,

    kindergarten_experience: null,
    kindergarten_interest: null,
    groundwork_landscaping_experience: null,
    groundwork_landscaping_interest: null,
    first_aid_experience: null,
    first_aid_interest: null,
    car_detailing_experience: null,
    car_detailing_interest: null,
    stocktaking_experience: null,
    stocktaking_interest: null,
    pruefservice_experience: null,
    pruefservice_interest: null,
    cleaning_experience: null,
    cleaning_interest: null,
    accounting_experience: null,
    accounting_interest: null,
    logistics_experience: null,
    logistics_interest: null,
    event_arrangement_leadership_experience: null,
    event_arrangement_leadership_interest: null,
    office_administration_experience: null,
    office_administration_interest: null,
    construction_installation_experience: null,
    construction_installation_interest: null,
    ventilation_installation_experience: null,
    ventilation_installation_interest: null,
    glass_railing_installation_experience: null,
    glass_railing_installation_interest: null,
    plumbing_experience: null,
    plumbing_interest: null,
    electrician_experience: null,
    electrician_interest: null,
    automatician_low_voltage_fitter_experience: null,
    automatician_low_voltage_fitter_interest: null,
    industrial_electricity_textile_production_experience: null,
    industrial_electricity_textile_production_interest: null,
    skilled_worker_concrete_experience: null,
    skilled_worker_concrete_interest: null,
    machine_operator_experience: null,
    machine_operator_interest: null,
    carpenter_woodworking_with_trade_certificate_experience: null,
    carpenter_woodworking_with_trade_certificate_interest: null,
    carpenter_woodworking_assistant_experience: null,
    carpenter_woodworking_assistant_interest: null,
    cabinet_maker_experience: null,
    cabinet_maker_interest: null,
    painting_experience: null,
    painting_interest: null,
    cooking_experience: null,
    cooking_interest: null,
    waiter_catering_experience: null,
    waiter_catering_interest: null,
    production_of_bakery_products_experience: null,
    production_of_bakery_products_interest: null,
    composing_experience: null,
    composing_interest: null,
    electronic_music_experience: null,
    electronic_music_interest: null,
    other_music_experience: null,
    other_music_interest: null,
    helpdesk_experience: null,
    helpdesk_interest: null,
    software_development_experience: null,
    software_development_interest: null,
    ux_designer_experience: null,
    ux_designer_interest: null,
    websites_experience: null,
    websites_interest: null,
    other_it_experience: null,
    other_it_interest: null,
    design_experience: null,
    design_interest: null,
    film_production_experience: null,
    film_production_interest: null,
    film_editing_experience: null,
    film_editing_interest: null,
    animation_experience: null,
    animation_interest: null,
    other_media_film_experience: null,
    other_media_film_interest: null,
    sound_experience: null,
    sound_interest: null,
    light_experience: null,
    light_interest: null,
    graphics_experience: null,
    graphics_interest: null
})

onBeforeMount(async () => {
    store.access_token = await directus.auth.staticToken

    await getApplication()
    setDocumentsListener()
})

function setDocumentsListener() {
    document.querySelectorAll('input[type="file"]').forEach(i => {
        i.addEventListener("change", setFileContentInData)
    })
}

async function getApplication() {
    // Get application
    const application = await directus.items("applications").readByQuery({
        filter: {
            id: { _eq: route.params.id },
        },
        fields: ['*', 'references.*.*', 'local_church.*', 'cv.*', 'profile_picture.*.*', 'passport_id_card.*', 'european_health_insurance_card.*', 'driver_license_copy.*', 'certificates.*', 'work_certificates.*', 'social_security_card.*', 'criminal_record_check.*'],
    })
    if (!application.data.length) return

    store.application = application.data[0]

    if (store.application.status == 'draft')
        router.push({ path: '/registration' })

    store.additional_comment = store.application.additional_comment
    store.tax_id = store.application.tax_id
    store.portfolio = store.application.portfolio
    setInitialDocuments()

    store.work_reference_person1_name = store.application.work_reference_person1_name
    store.work_reference_person1_phone_number = store.application.work_reference_person1_phone_number
    store.work_reference_person1_email_address = store.application.work_reference_person1_email_address
    store.work_reference_person1_work_type = store.application.work_reference_person1_work_type
    store.work_reference_person2_name = store.application.work_reference_person2_name
    store.work_reference_person2_phone_number = store.application.work_reference_person2_phone_number
    store.work_reference_person2_email_address = store.application.work_reference_person2_email_address
    store.work_reference_person2_work_type = store.application.work_reference_person2_work_type

    store.kindergarten_experience = store.application.kindergarten_experience
    store.kindergarten_interest = store.application.kindergarten_interest
    store.groundwork_landscaping_experience = store.application.groundwork_landscaping_experience
    store.groundwork_landscaping_interest = store.application.groundwork_landscaping_interest
    store.first_aid_experience = store.application.first_aid_experience
    store.first_aid_interest = store.application.first_aid_interest
    store.car_detailing_experience = store.application.car_detailing_experience
    store.car_detailing_interest = store.application.car_detailing_interest
    store.stocktaking_experience = store.application.stocktaking_experience
    store.stocktaking_interest = store.application.stocktaking_interest
    store.pruefservice_experience = store.application.pruefservice_experience
    store.pruefservice_interest = store.application.pruefservice_interest
    store.cleaning_experience = store.application.cleaning_experience
    store.cleaning_interest = store.application.cleaning_interest
    store.accounting_experience = store.application.accounting_experience
    store.accounting_interest = store.application.accounting_interest
    store.logistics_experience = store.application.logistics_experience
    store.logistics_interest = store.application.logistics_interest
    store.event_arrangement_leadership_experience = store.application.event_arrangement_leadership_experience
    store.event_arrangement_leadership_interest = store.application.event_arrangement_leadership_interest
    store.office_administration_experience = store.application.office_administration_experience
    store.office_administration_interest = store.application.office_administration_interest
    store.construction_installation_experience = store.application.construction_installation_experience
    store.construction_installation_interest = store.application.construction_installation_interest
    store.ventilation_installation_experience = store.application.ventilation_installation_experience
    store.ventilation_installation_interest = store.application.ventilation_installation_interest
    store.glass_railing_installation_experience = store.application.glass_railing_installation_experience
    store.glass_railing_installation_interest = store.application.glass_railing_installation_interest
    store.plumbing_experience = store.application.plumbing_experience
    store.plumbing_interest = store.application.plumbing_interest
    store.electrician_experience = store.application.electrician_experience
    store.electrician_interest = store.application.electrician_interest
    store.automatician_low_voltage_fitter_experience = store.application.automatician_low_voltage_fitter_experience
    store.automatician_low_voltage_fitter_interest = store.application.automatician_low_voltage_fitter_interest
    store.industrial_electricity_textile_production_experience = store.application.industrial_electricity_textile_production_experience
    store.industrial_electricity_textile_production_interest = store.application.industrial_electricity_textile_production_interest
    store.skilled_worker_concrete_experience = store.application.skilled_worker_concrete_experience
    store.skilled_worker_concrete_interest = store.application.skilled_worker_concrete_interest
    store.machine_operator_experience = store.application.machine_operator_experience
    store.machine_operator_interest = store.application.machine_operator_interest
    store.carpenter_woodworking_with_trade_certificate_experience = store.application.carpenter_woodworking_with_trade_certificate_experience
    store.carpenter_woodworking_with_trade_certificate_interest = store.application.carpenter_woodworking_with_trade_certificate_interest
    store.carpenter_woodworking_assistant_experience = store.application.carpenter_woodworking_assistant_experience
    store.carpenter_woodworking_assistant_interest = store.application.carpenter_woodworking_assistant_interest
    store.cabinet_maker_experience = store.application.cabinet_maker_experience
    store.cabinet_maker_interest = store.application.cabinet_maker_interest
    store.painting_experience = store.application.painting_experience
    store.painting_interest = store.application.painting_interest
    store.cooking_experience = store.application.cooking_experience
    store.cooking_interest = store.application.cooking_interest
    store.waiter_catering_experience = store.application.waiter_catering_experience
    store.waiter_catering_interest = store.application.waiter_catering_interest
    store.production_of_bakery_products_experience = store.application.production_of_bakery_products_experience
    store.production_of_bakery_products_interest = store.application.production_of_bakery_products_interest
    store.composing_experience = store.application.composing_experience
    store.composing_interest = store.application.composing_interest
    store.electronic_music_experience = store.application.electronic_music_experience
    store.electronic_music_interest = store.application.electronic_music_interest
    store.other_music_experience = store.application.other_music_experience
    store.other_music_interest = store.application.other_music_interest
    store.helpdesk_experience = store.application.helpdesk_experience
    store.helpdesk_interest = store.application.helpdesk_interest
    store.software_development_experience = store.application.software_development_experience
    store.software_development_interest = store.application.software_development_interest
    store.ux_designer_experience = store.application.ux_designer_experience
    store.ux_designer_interest = store.application.ux_designer_interest
    store.websites_experience = store.application.websites_experience
    store.websites_interest = store.application.websites_interest
    store.other_it_experience = store.application.other_it_experience
    store.other_it_interest = store.application.other_it_interest
    store.design_experience = store.application.design_experience
    store.design_interest = store.application.design_interest
    store.film_production_experience = store.application.film_production_experience
    store.film_production_interest = store.application.film_production_interest
    store.film_editing_experience = store.application.film_editing_experience
    store.film_editing_interest = store.application.film_editing_interest
    store.animation_experience = store.application.animation_experience
    store.animation_interest = store.application.animation_interest
    store.other_media_film_experience = store.application.other_media_film_experience
    store.other_media_film_interest = store.application.other_media_film_interest
    store.sound_experience = store.application.sound_experience
    store.sound_interest = store.application.sound_interest
    store.light_experience = store.application.light_experience
    store.light_interest = store.application.light_interest
    store.graphics_experience = store.application.graphics_experience
    store.graphics_interest = store.application.graphics_interest

    if (store.kindergarten_experience) store.selectedWorkArea.push('kindergarten_experience')
    if (store.groundwork_landscaping_experience) store.selectedWorkArea.push('groundwork_landscaping_experience')
    if (store.first_aid_experience) store.selectedWorkArea.push('first_aid_experience')
    if (store.car_detailing_experience) store.selectedWorkArea.push('car_detailing_experience')
    if (store.stocktaking_experience) store.selectedWorkArea.push('stocktaking_experience')
    if (store.pruefservice_experience) store.selectedWorkArea.push('pruefservice_experience')
    if (store.cleaning_experience) store.selectedWorkArea.push('cleaning_experience')
    if (store.accounting_experience) store.selectedWorkArea.push('accounting_experience')
    if (store.logistics_experience) store.selectedWorkArea.push('logistics_experience')
    if (store.event_arrangement_leadership_experience) store.selectedWorkArea.push('event_arrangement_leadership_experience')
    if (store.office_administration_experience) store.selectedWorkArea.push('office_administration_experience')
    if (store.construction_installation_experience) store.selectedWorkArea.push('construction_installation_experience')
    if (store.ventilation_installation_experience) store.selectedWorkArea.push('ventilation_installation_experience')
    if (store.glass_railing_installation_experience) store.selectedWorkArea.push('glass_railing_installation_experience')
    if (store.plumbing_experience) store.selectedWorkArea.push('plumbing_experience')
    if (store.electrician_experience) store.selectedWorkArea.push('electrician_experience')
    if (store.automatician_low_voltage_fitter_experience) store.selectedWorkArea.push('automatician_low_voltage_fitter_experience')
    if (store.industrial_electricity_textile_production_experience) store.selectedWorkArea.push('industrial_electricity_textile_production_experience')
    if (store.skilled_worker_concrete_experience) store.selectedWorkArea.push('skilled_worker_concrete_experience')
    if (store.machine_operator_experience) store.selectedWorkArea.push('machine_operator_experience')
    if (store.carpenter_woodworking_with_trade_certificate_experience) store.selectedWorkArea.push('carpenter_woodworking_with_trade_certificate_experience')
    if (store.carpenter_woodworking_assistant_experience) store.selectedWorkArea.push('carpenter_woodworking_assistant_experience')
    if (store.cabinet_maker_experience) store.selectedWorkArea.push('cabinet_maker_experience')
    if (store.painting_experience) store.selectedWorkArea.push('painting_experience')
    if (store.cooking_experience) store.selectedWorkArea.push('cooking_experience')
    if (store.waiter_catering_experience) store.selectedWorkArea.push('waiter_catering_experience')
    if (store.production_of_bakery_products_experience) store.selectedWorkArea.push('production_of_bakery_products_experience')
    if (store.composing_experience) store.selectedWorkArea.push('composing_experience')
    if (store.electronic_music_experience) store.selectedWorkArea.push('electronic_music_experience')
    if (store.other_music_experience) store.selectedWorkArea.push('other_music_experience')
    if (store.helpdesk_experience) store.selectedWorkArea.push('helpdesk_experience')
    if (store.software_development_experience) store.selectedWorkArea.push('software_development_experience')
    if (store.ux_designer_experience) store.selectedWorkArea.push('ux_designer_experience')
    if (store.websites_experience) store.selectedWorkArea.push('websites_experience')
    if (store.other_it_experience) store.selectedWorkArea.push('other_it_experience')
    if (store.design_experience) store.selectedWorkArea.push('design_experience')
    if (store.film_production_experience) store.selectedWorkArea.push('film_production_experience')
    if (store.film_editing_experience) store.selectedWorkArea.push('film_editing_experience')
    if (store.animation_experience) store.selectedWorkArea.push('animation_experience')
    if (store.other_media_film_experience) store.selectedWorkArea.push('other_media_film_experience')
    if (store.sound_experience) store.selectedWorkArea.push('sound_experience')
    if (store.light_experience) store.selectedWorkArea.push('light_experience')
    if (store.graphics_experience) store.selectedWorkArea.push('graphics_experience')
}

function getDocument(id) {
    return store.files.find(f => f.fieldName == id)
}

function setInitialDocuments() {
    const documents = ['cv', 'profile_picture', 'passport_id_card', 'european_health_insurance_card', 'driver_license_copy', 'certificates', 'work_certificates', 'social_security_card', 'criminal_record_check']

    documents.filter(fieldName => store.application[fieldName]).forEach(fieldName => {
        store.files.push({
            fieldName: fieldName,
            tempName: store.application[fieldName].filename_download,
            ext: getFileExtension(store.application[fieldName].filename_download),
            size: getFileSize(store.application[fieldName].filesize),
            assetId: store.application[fieldName].id
        })
    })
}

function getAteamAssignedInterviewerLink() {
    return store.application.ateam_interviewer
        ? `https://app.simplymeet.me/${store.application.ateam_interviewer}/schedule?is_widget=1&view=compact`
        : ''
}

function getWntAssignedInterviewerLink() {
    return store.application.wnt_interviewer
        ? `https://app.simplymeet.me/${store.application.wnt_interviewer}/schedule?is_widget=1&view=compact`
        : ''
}

function setFileContentInData(event) {
    const data = event.target.files[0]
    const id = event.target.getAttribute('id')
    store.files.push({
        fieldName: id,
        data: data,
        tempName: data.name,
        ext: getFileExtension(data.name),
        size: getFileSize(data.size)
    })

    setUpdatedDocuments(id)
}

function setUpdatedDocuments(id) {
    store.updatedDocuments.push(id)
    store.updatedDocuments = [...new Set(store.updatedDocuments)]
}

function cancelFileUpload(event) {
    const id = event.target.parentElement.dataset.id
    document.querySelector('input#' + id).value = ''
    store.files = store.files.filter(f => f.fieldName != id)

    setUpdatedDocuments(id)
}

function nullifyAreaExperience(area) {
  const experience = area.value
  const interest = experience.replace('experience', 'interest')

  store[experience] = null
  store[interest] = null
}

async function updateApplication() {
    store.updateFailed = false
    store.formLoading = true

    try {
        await uploadFiles()

        let data = {
            additional_comment: store.additional_comment,
            tax_id: store.tax_id,
            portfolio: store.portfolio
        }

        store.updatedDocuments.forEach(id => {
            const file = store.files.find(f => f.fieldName == id)
            data[id] = file ? file.assetId : null
        })

        await directus.items('applications').updateOne(store.application.id, data)

        store.formLoading = false
        location.reload()
    }
    catch (error) {
        store.updateFailed = true
        store.formLoading = false
        console.error("There was an error!", error)
    }
}

async function updateKnowledgeExperienceAreas() {
    store.updateFailed = false
    store.formLoading = true

    try {
        let data = {
            work_reference_person1_name: store.work_reference_person1_name,
            work_reference_person1_phone_number: store.work_reference_person1_phone_number,
            work_reference_person1_email_address: store.work_reference_person1_email_address,
            work_reference_person1_work_type: store.work_reference_person1_work_type,
            work_reference_person2_name: store.work_reference_person2_name,
            work_reference_person2_phone_number: store.work_reference_person2_phone_number,
            work_reference_person2_email_address: store.work_reference_person2_email_address,
            work_reference_person2_work_type: store.work_reference_person2_work_type,

            kindergarten_experience: store.kindergarten_experience,
            kindergarten_interest: store.kindergarten_interest,
            groundwork_landscaping_experience: store.groundwork_landscaping_experience,
            groundwork_landscaping_interest: store.groundwork_landscaping_interest,
            first_aid_experience: store.first_aid_experience,
            first_aid_interest: store.first_aid_interest,
            car_detailing_experience: store.car_detailing_experience,
            car_detailing_interest: store.car_detailing_interest,
            stocktaking_experience: store.stocktaking_experience,
            stocktaking_interest: store.stocktaking_interest,
            pruefservice_experience: store.pruefservice_experience,
            pruefservice_interest: store.pruefservice_interest,
            cleaning_experience: store.cleaning_experience,
            cleaning_interest: store.cleaning_interest,
            accounting_experience: store.accounting_experience,
            accounting_interest: store.accounting_interest,
            logistics_experience: store.logistics_experience,
            logistics_interest: store.logistics_interest,
            event_arrangement_leadership_experience: store.event_arrangement_leadership_experience,
            event_arrangement_leadership_interest: store.event_arrangement_leadership_interest,
            office_administration_experience: store.office_administration_experience,
            office_administration_interest: store.office_administration_interest,
            construction_installation_experience: store.construction_installation_experience,
            construction_installation_interest: store.construction_installation_interest,
            ventilation_installation_experience: store.ventilation_installation_experience,
            ventilation_installation_interest: store.ventilation_installation_interest,
            glass_railing_installation_experience: store.glass_railing_installation_experience,
            glass_railing_installation_interest: store.glass_railing_installation_interest,
            plumbing_experience: store.plumbing_experience,
            plumbing_interest: store.plumbing_interest,
            electrician_experience: store.electrician_experience,
            electrician_interest: store.electrician_interest,
            automatician_low_voltage_fitter_experience: store.automatician_low_voltage_fitter_experience,
            automatician_low_voltage_fitter_interest: store.automatician_low_voltage_fitter_interest,
            industrial_electricity_textile_production_experience: store.industrial_electricity_textile_production_experience,
            industrial_electricity_textile_production_interest: store.industrial_electricity_textile_production_interest,
            skilled_worker_concrete_experience: store.skilled_worker_concrete_experience,
            skilled_worker_concrete_interest: store.skilled_worker_concrete_interest,
            machine_operator_experience: store.machine_operator_experience,
            machine_operator_interest: store.machine_operator_interest,
            carpenter_woodworking_with_trade_certificate_experience: store.carpenter_woodworking_with_trade_certificate_experience,
            carpenter_woodworking_with_trade_certificate_interest: store.carpenter_woodworking_with_trade_certificate_interest,
            carpenter_woodworking_assistant_experience: store.carpenter_woodworking_assistant_experience,
            carpenter_woodworking_assistant_interest: store.carpenter_woodworking_assistant_interest,
            cabinet_maker_experience: store.cabinet_maker_experience,
            cabinet_maker_interest: store.cabinet_maker_interest,
            painting_experience: store.painting_experience,
            painting_interest: store.painting_interest,
            cooking_experience: store.cooking_experience,
            cooking_interest: store.cooking_interest,
            waiter_catering_experience: store.waiter_catering_experience,
            waiter_catering_interest: store.waiter_catering_interest,
            production_of_bakery_products_experience: store.production_of_bakery_products_experience,
            production_of_bakery_products_interest: store.production_of_bakery_products_interest,
            composing_experience: store.composing_experience,
            composing_interest: store.composing_interest,
            electronic_music_experience: store.electronic_music_experience,
            electronic_music_interest: store.electronic_music_interest,
            other_music_experience: store.other_music_experience,
            other_music_interest: store.other_music_interest,
            helpdesk_experience: store.helpdesk_experience,
            helpdesk_interest: store.helpdesk_interest,
            software_development_experience: store.software_development_experience,
            software_development_interest: store.software_development_interest,
            ux_designer_experience: store.ux_designer_experience,
            ux_designer_interest: store.ux_designer_interest,
            websites_experience: store.websites_experience,
            websites_interest: store.websites_interest,
            other_it_experience: store.other_it_experience,
            other_it_interest: store.other_it_interest,
            design_experience: store.design_experience,
            design_interest: store.design_interest,
            film_production_experience: store.film_production_experience,
            film_production_interest: store.film_production_interest,
            film_editing_experience: store.film_editing_experience,
            film_editing_interest: store.film_editing_interest,
            animation_experience: store.animation_experience,
            animation_interest: store.animation_interest,
            other_media_film_experience: store.other_media_film_experience,
            other_media_film_interest: store.other_media_film_interest,
            sound_experience: store.sound_experience,
            sound_interest: store.sound_interest,
            light_experience: store.light_experience,
            light_interest: store.light_interest,
            graphics_experience: store.graphics_experience,
            graphics_interest: store.graphics_interest
        }

        await directus.items('applications').updateOne(store.application.id, data)

        store.formLoading = false
        location.reload()
    }
    catch (error) {
        store.updateFailed = true
        store.formLoading = false
        console.error("There was an error!", error)
    }
}

async function uploadFiles() {
    await Promise.all(store.updatedDocuments.map(async (id) => {
        const file = store.files.find(f => f.fieldName == id)

        if (file) {
            const fileName = auth.userData['name'] + ' - ' + getFileName(id)

            const form = new FormData()
            form.append('title', fileName)
            form.append('file', file.data)

            const uploadedFile = await directus.files.createOne(form)
            file.assetId = uploadedFile.id
        }
    }))
}
</script>