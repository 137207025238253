<template>
    <div class="grid" v-if="props.references.filter(r => r[props.ref_type]).length">
        <div class="flex items-center gap-1 text-lg py-2 rounded-lg cursor-pointer transition text-dark-green-500 hover:text-dark-green-900  " @click="isExpanded = !isExpanded" :aria-pressed="isExpanded ? 'true' : 'false'">
            <div class="transition ease-linear w-4 h-4" :class="{ 'rotate-180': isExpanded }">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" class="w-full" preserveAspectRatio="none" viewBox="0 0 24 24">
                    <path stroke="hsl(175, 100%, 10%)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 9-7 7-7-7" />
                </svg>
            </div>
            <span class="text-base font-medium underline">{{ t('reference.form.seeOthersFeedback') }}</span>
        </div>
        <div class="grid" :class="{ hidden: !isExpanded, 'bg-neutral-300/60': isExpanded, 'rounded-lg': isExpanded }">
            <div class="px-4 py-2" v-for="reference in props.references.filter(r => r[props.ref_type])" :key="reference.id">
                <span class="text-xs font-semibold text-neutral-900/40">{{ reference.user_created.first_name }} {{ reference.user_created.last_name }}:</span>
                <p class="whitespace-pre-wrap">{{ reference[props.ref_type] }}</p>
            </div>
        </div>
        <div class="h-px bg-neutral-300 mt-4" :class="{ hidden: props.hide }"></div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'


const { t } = useI18n({
    inheritLocale: true,
    useScope: 'local'
})

const isExpanded = ref(false)
const props = defineProps(['references', 'ref_type', 'hide'])
</script>