<template>
    <div class="container h-full relative">
        <div class="grid gap-8" v-if="store.currentPage">
            <router-link to="/" class="flex gap-2">
                <KeyboardArrowLeftIcon class="h-6" />
                <span class="text-lg leading-6 font-semibold">{{ store.currentPage.title }}</span>
            </router-link>
            <div v-html="store.currentPage.content" class="whitespace-pre-wrap"></div>
        </div>
        <div v-else class="flex h-full justify-center items-center">
            <BccSpinner size="xs" />
        </div>
    </div>
</template>
  
<script setup>
import { onBeforeMount, reactive, watch } from 'vue'
import { useDirectusStore } from '../stores/directus'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { BccSpinner } from '@bcc-code/design-library-vue';
import { KeyboardArrowLeftIcon } from '@bcc-code/icons-vue'

const route = useRoute()
const directusStore = useDirectusStore()
const directus = directusStore.directus
const user = directusStore.user

const { t } = useI18n({
    inheritLocale: true,
    useScope: 'local'
})

const store = reactive({
    currentPage: null,
    pages: []
})

onBeforeMount(async () => {
    await getPage()
})

watch(
    route,
    () => changePage()
)

async function getPage() {
    // Reset page content
    store.currentPage = null

    const pages = await directus.items("pages").readByQuery({
        filter: {
            status: { _eq: 'published' }
        },
        fields: ['*']
    })
    if (!pages.data.length) return

    if (isParticipant()) {
        let currentOrComingEmployment = user.current_employment
            ? user.employments.find(employment => employment.id == user.current_employment.key)
            : user.employments[0] // Get the first employment

        pages.data.forEach(page => {
            if (page.targeted_locations.length && !page.targeted_locations.includes(currentOrComingEmployment.location.id))
                return
            
            store.pages.push(page)
        })
    }
    else {
        store.pages = pages.data
    }

    store.currentPage = store.pages.find(page => page.slug == route.params.slug)
}

function changePage() {
    store.currentPage = store.pages.find(page => page.slug == route.params.slug)
}

const isParticipant = () => {
    return directusStore.userHasRole('applicant') && user.employments.length
}
</script>

<style>
    .whitespace-pre-wrap img {
        display: inline-block;
    }

    .whitespace-pre-wrap ul {
        list-style-type: disc;
        list-style-position: inside;
        line-height: normal;
    }

    .whitespace-pre-wrap tbody tr td:first-child {
        vertical-align: top;
        padding-top: 20px;
    }

    @media screen and (max-width: 480px) {
        .whitespace-pre-wrap colgroup col {
            width: 100% !important;
        }

        .whitespace-pre-wrap tbody,
        .whitespace-pre-wrap tr {
            display: flex;
            flex-direction: column;
        }
    }
</style>