<template>
    <div class="container h-full relative flex flex-col gap-4">
        <div v-if="store.application" class="flex flex-col gap-8 mb-8">
            <div>
                <div class="rounded-lg grid grid-cols-[1fr_auto] gap-2 md:gap-4">
                    <div>
                        <h2 class="text-xl leading-6 text-dark-green-500 font-semibold">{{ t('dashboard.application_status') }}</h2>

                        <div class="mt-2">
                            <div>
                                <span class="font-bold">{{ t('dashboard.name') }}:</span> {{ store.application.first_name }} {{ store.application.last_name }}
                            </div>
                            <div>
                                <span class="font-bold">{{ t('form.year_group.title') }}:</span> {{ store.application.year_group || '-' }}
                            </div>
                            <div>
                                <span class="font-bold">{{ t('dashboard.location') }}:</span> -
                            </div>
                        </div>
                    </div>

                    <div v-if="store.application.profile_picture" class="print:absolute print:right-0 print:top-0">
                        <img :src="directus._url + '/assets/' + store.application.profile_picture.id + '?access_token=' + store.access_token"
                            class="rounded-lg" width="150" />
                    </div>
                </div>

                <div>
                    <span class="font-semibold">{{ t('dashboard.application') }}: </span>
                    <a class="underline" :href="'/application/' + store.application.id">{{ t('dashboard.reviewOrUpdateApplication') }}</a>
                </div>
            </div>

            <div class="flex flex-col gap-2 md:gap-4">
                <h2 class="text-lg leading-6 text-dark-green-500 font-semibold">{{ t('dashboard.statusAndTasks') }}</h2>

                <div class="flex flex-row gap-4 hidden">
                    <div class="w-5 h-5 rounded-full bg-teal-600 mt-[3px]"></div>
                    <div><span class="font-semibold">{{ t('dashboard.application_status') }}:</span> {{ t('dashboard.status.' + store.application.status) }}</div>
                </div>

                <div class="flex flex-row gap-4">
                    <div class="w-5 h-5 rounded-full bg-red-500 mt-[3px]" v-if="['not_started', 'too_low'].includes(store.application.language_status) || !store.application.language_status"></div>
                    <div class="w-5 h-5 rounded-full bg-yellow-500 mt-[3px]" v-if="store.application.language_status == 'ready_for_norwegian_interview'"></div>
                    <div class="w-5 h-5 rounded-full bg-dark-green-100 mt-[3px]" v-if="store.application.language_status == 'passed'"></div>

                    <div>
                        <div><span class="font-semibold">{{ t('menu.languageStatus') }}:</span> {{ store.application.language_status ? t('languageAssessment.form.language_status.' + store.application.language_status) : '-' }}</div>
                        <div><a class="underline" :href="'/application/' + store.application.id + '/language-status'">{{ t('dashboard.clickHereForMoreInformation') }}</a></div>
                    </div>
                </div>

                <div class="flex flex-row gap-4" v-if="store.application.status == 'ready_for_interview' && store.application.ateam_interviewer">
                    <div class="w-5 h-5 rounded-full bg-red-500 mt-[3px]" v-if="store.application.ateam_interview_status == 'interview_missing'"></div>
                    <div class="w-5 h-5 rounded-full bg-yellow-500 mt-[3px]" v-if="store.application.ateam_interview_status == 'interview_scheduled'"></div>
                    <div class="w-5 h-5 rounded-full bg-dark-green-100 mt-[3px]" v-if="store.application.ateam_interview_status == 'interview_completed'"></div>

                    <div>
                        <div><span class="font-semibold">{{ t('dashboard.ateamInterview') }}:</span> {{ t('dashboard.interviewStatus.' + store.application.ateam_interview_status) }}</div>
                        <div v-if="store.application.ateam_interview_status == 'interview_missing'"><a class="underline" :href="'/application/' + store.application.id">{{ t('dashboard.clickHereToScheduleInterview') }}</a></div>
                    </div>
                </div>

                <div class="flex flex-row gap-4" v-if="store.application.status == 'ready_for_interview' && store.application.wnt_interviewer">
                    <div class="w-5 h-5 rounded-full bg-red-500 mt-[3px]" v-if="store.application.wnt_interview_status == 'interview_missing'"></div>
                    <div class="w-5 h-5 rounded-full bg-yellow-500 mt-[3px]" v-if="store.application.wnt_interview_status == 'interview_scheduled'"></div>
                    <div class="w-5 h-5 rounded-full bg-dark-green-100 mt-[3px]" v-if="store.application.wnt_interview_status == 'interview_completed'"></div>

                    <div>
                        <div><span class="font-semibold">{{ t('dashboard.wntInterview') }}:</span> {{ t('dashboard.interviewStatus.' + store.application.wnt_interview_status) }}</div>
                        <div v-if="store.application.wnt_interview_status == 'interview_missing'"><a class="underline" :href="'/application/' + store.application.id">{{ t('dashboard.clickHereToScheduleInterview') }}</a></div>
                    </div>
                </div>

                <div class="flex flex-row gap-4">
                    <div class="w-5 h-5 rounded-full bg-red-500 mt-[3px]"></div>
                    <div>
                        <div><span class="font-semibold">{{ t('dashboard.documents') }}:</span></div>
                        <div><a class="underline" :href="'/application/' + store.application.id">{{ t('dashboard.clickHereToUploadDocuments') }}</a></div>
                        <ul class="list-disc list-inside flex flex-col ml-4">
                            <li v-for="document in store.missingDocuments" :key="document">{{ t('form.' + document + '.title') }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <header>
            <div v-html="store.home"></div>
        </header>

        <div class="mb-6 flex gap-4" v-if="!store.locations.length">
            <v-select
                class="w-60"
                v-model="store.selectedLocation"
                :placeholder="t('home.selectLocation')"
                :reduce="(option) => option.value"
                :options="store.locations"
            />
            
            <v-select
                class="w-60 hidden"
                v-model="store.selectedYearGroup"
                :placeholder="t('home.selectYearGroup')"
                :reduce="(option) => option.value"
                :options="store.yearGroups"
            />
        </div>

        <div v-if="!store.loading">
            <ul class="list-decimal list-inside flex flex-col gap-3 mt-2">
                <li v-for="page in store.selectedPages" :key="page.id">
                    <a class="underline" :href="'/info/' + page.slug">{{ page.title }}</a>
                </li>
            </ul>
        </div>
        <div v-else class="flex h-full justify-center items-center">
            <BccSpinner size="xs" />
        </div>
    </div>
</template>
  
<script setup>
import { onBeforeMount, reactive, watch } from 'vue'
import { useDirectusStore } from '../stores/directus'
import { useI18n } from 'vue-i18n'
import { BccSpinner } from '@bcc-code/design-library-vue';

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

const directusStore = useDirectusStore()
const directus = directusStore.directus
const user = directusStore.user

const { t } = useI18n({
    inheritLocale: true,
    useScope: 'local'
})

const store = reactive({
    access_token: null,

    loading: true,
    home: null,
    pages: [],
    locations: [],
    yearGroups: ['24/25'],
    selectedPages: [],
    selectedLocation: null,
    selectedYearGroup: null,
    application: null,

    documentsNeeded: ['profile_picture', 'passport_id_card', 'criminal_record_check'],
    missingDocuments: []
})

onBeforeMount(async () => {
    store.access_token = await directus.auth.staticToken

    if (directusStore.userHasRole('applicant')) {
        await getApplication()
    }

    await getPages()

    store.loading = false
})

watch(
    () => store.selectedLocation,
    () => changeInfoPages()
)
watch(
    () => store.selectedYearGroup,
    () => changeInfoPages()
)

async function getApplication() {
    const applications = await directus.items("applications").readByQuery({
        filter: {
            created_by: { _eq: user.id }
        },
        fields: ['id', 'status', 'language_status', 'first_name', 'last_name', 'year_group', 'cv.*', 'profile_picture.*.*', 'passport_id_card.*', 'european_health_insurance_card.*', 'driver_license_copy.*', 'certificates.*', 'portfolio', 'work_certificates.*', 'social_security_card.*', 'criminal_record_check.*', 'ateam_interview_status', 'wnt_interview_status', 'ateam_interviewer', 'wnt_interviewer']
    })

    if (!applications.data.length) return

    const applicationsCount = applications.data.length
    store.application = applications.data[applicationsCount - 1]

    getMissingDocuments()
}

async function getPages() {
    const pages = await directus.items("pages").readByQuery({
        fields: ['*', 'targeted_locations.locations_id.*']
    })
    if (!pages.data.length) return
    
    store.home = pages.data.find(page => page.slug == 'home')?.content
    store.pages = pages.data.filter(page => page.status == 'published')

    let currentOrComingEmployment = null

    if (user.current_employment || user.employments?.length) {
        currentOrComingEmployment = user.current_employment
            ? user.employments.find(employment => employment.id == user.current_employment.key)
            : user.employments[0] // Get the first employment
    }

    store.selectedYearGroup = store.application?.year_group

    store.pages.forEach(page => {
        if (!page.targeted_locations.length) {
            return
        }

        const pageLocations = page.targeted_locations
            .map(location => ({
                value: location.locations_id?.id,
                label: location.locations_id?.name
            }))

        store.locations = store.locations.concat(pageLocations)

        if (
                (
                    currentOrComingEmployment?.location
                    && page.targeted_locations.length
                    && !page.targeted_locations.map(l => l.locations_id.id).includes(currentOrComingEmployment.location.id)
                )
                ||
                (
                    page.year_groups
                    && !page.year_groups.includes(store.selectedYearGroup)
                )
            )
        {
            return
        }
        
        store.selectedPages.push(page)
    })

    // Unique locations
    store.locations = [...new Map(store.locations.map(location => [location.value, location])).values()]

    store.selectedLocation = currentOrComingEmployment?.location?.id
}

function getMissingDocuments() {
    store.documentsNeeded.forEach(document => {
        if (!store.application[document]) {
            store.missingDocuments.push(document)
        }
    })
}

function changeInfoPages() {
    store.selectedPages = []

    store.pages.forEach(page => {
        if (page.targeted_locations.length
            && !page.targeted_locations
                .map(l => l.locations_id?.id)
                .includes(store.selectedLocation)
            || 
            (
                page.year_groups
                && !page.year_groups.includes(store.selectedYearGroup)
            )
        ) {
            return
        }

        store.selectedPages.push(page)
    })
}
</script>