import { defineStore } from "pinia";
import createAuth0Client from "@auth0/auth0-spa-js";

const authConfig = require("./auth_config.json");

export const useAuthStore = defineStore("auth", {
  state: () => ({
    auth0: null,
    userData: null,
    claims: null,
  }),
  getters: {
    async getIdToken() {
      this.claims = await this.auth0?.getIdTokenClaims();
      return this.claims ? this.claims.__raw : null;
    },
  },
  actions: {
    async setupAuth() {
      this.auth0 = await createAuth0Client({
        domain:
          window.location.hostname == authConfig.publicUrl
            ? authConfig.domain
            : authConfig.sandboxDomain,
        client_id:
          window.location.hostname == authConfig.publicUrl
            ? authConfig.clientId
            : authConfig.sandboxClientId,
        redirect_uri: window.location.origin,
        scope: "openid profile email church phone",
        cacheLocation: "localstorage",
        useRefreshTokens: false,
      });

      const query = window.location.search;
      if (query.includes("code=") && query.includes("state=")) {
        await this.auth0.handleRedirectCallback();
        location.href = sessionStorage.getItem("initial_url");
      }

      if (await this.auth0.isAuthenticated()) {
        this.userData = await this.auth0.getUser();
      } else {
        let url = window.location.href;

        // Sometimes it redirects to "/null", so we remove it here
        url = url.replace("null", "");

        sessionStorage.setItem("initial_url", url);
        await this.auth0.loginWithRedirect({
          redirect_uri: this.auth0.redirect_uri,
        });
      }
    },
  },
});
