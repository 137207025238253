<template>
    <nav class="grid">
        <Link to="/" :icon="HomeIcon">
        {{ $t('menu.home') }}
        </Link>

        <Link to="/dashboard" :icon="DashboardIcon" v-if="!directusStore.userHasRole('employer') && !directusStore.userHasRole('stedsansvarlig')">
        {{ $t('menu.dashboard') }}
        </Link>

        <Link to="/dashboard/language" :icon="RateReviewIcon" v-if="directusStore.isAdmin() || directusStore.userHasRole('norwegian_teacher') || directusStore.userHasRole('reference_person')">
        {{ $t('menu.norwegianAssessments') }}
        </Link>

        <div class="pl-9" v-if="directusStore.isAdmin() || directusStore.userHasRole('norwegian_teacher')">
            <Link to="/dashboard/language-classes" :icon="EditNoteIcon" class="w-full">
            {{ $t('menu.languageClasses') }}
            </Link>
        </div>

        <Link to="/dashboard/employments" :icon="GroupIcon" v-if="directusStore.isAdmin() || directusStore.userHasRole('employer') || directusStore.userHasRole('employment_agent')">
        {{ $t('menu.placements') }}
        </Link>

        <Link to="/dashboard/wnt-employments" :icon="GroupsIcon" v-if="directusStore.isAdmin() || directusStore.userHasRole('employer') || directusStore.userHasRole('employment_agent')">
        {{ $t('menu.wnt-placements') }}
        </Link>

        <Link to="/dashboard/participants" :icon="Diversity3Icon" v-if="directusStore.isAdmin() || directusStore.userHasRole('stedsansvarlig')">
        {{ $t('menu.participants') }}
        </Link>

        <Link to="/finance" :icon="CreditCardIcon" v-if="directusStore.userHasRole('administrator')">
        {{ $t('menu.myFinances') }}
        </Link>

        <Link to="/overnighting" :icon="SingleBedIcon" v-if="directusStore.isAdmin() || directusStore.userHasRole('stedsansvarlig') || (directusStore.userHasRole('applicant') && hasSubmittedApplications())">
        {{ $t('menu.overnighting') }}
        </Link>

        <span class="hidden md:flex items-center gap-3 mt-6 px-3 pb-1 text-tertiary font-bold">{{ $t('dashboard.actions.title') }}</span>

        <Link to="/incident/+" :icon="ReportIcon" v-if="directusStore.isAdmin() || directusStore.userHasRole('stedsansvarlig')">
        {{ $t('menu.reportIncident') }}
        </Link>

        <Link to="/registration" :icon="DescriptionIcon" v-if="directusStore.userHasRole('administrator') || directusStore.userHasRole('applicant')">
        {{ $t('menu.registration') }}
        </Link>
    </nav>
</template>

<script setup>
import Link from "./Link"
import { onBeforeMount, reactive } from 'vue'
import { useDirectusStore } from '../stores/directus'
import { HomeIcon, DashboardIcon, RateReviewIcon, EditNoteIcon, GroupIcon, GroupsIcon, Diversity3Icon, CreditCardIcon, SingleBedIcon, DescriptionIcon, ReportIcon } from '@bcc-code/icons-vue'

const directusStore = useDirectusStore()
const directus = directusStore.directus
const user = directusStore.user

const store = reactive({
    subMenuIsVisible: true,
    pages: []
})

onBeforeMount(async () => {
    await getPages()
})

async function getPages() {
    const pages = await directus.items("pages").readByQuery({
        filter: {
            status: { _eq: 'published' }
        },
        fields: ['title', 'slug', 'icon', 'targeted_locations']
    })
    if (!pages.data.length) return

    if (isParticipant()) {
        let currentOrComingEmployment = user.current_employment
            ? user.employments.find(employment => employment.id == user.current_employment.key)
            : user.employments[0] // Get the first employment

        pages.data.forEach(page => {
            if (page.targeted_locations.length && !page.targeted_locations.includes(currentOrComingEmployment.location.id))
                return
            
            store.pages.push(page)
        })
    }
    else {
        store.pages = pages.data
    }
}

const toggleSubMenu = () => {
    store.subMenuIsVisible = !store.subMenuIsVisible
}

const isParticipant = () => {
    return directusStore.userHasRole('applicant') && user.employments.length
}

const hasSubmittedApplications = () => {
    return directusStore.userHasRole('applicant') && user.applications.length
}
</script>