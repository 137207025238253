export function getCurrentDate() {
  const date = new Date();
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    "0",
  )}-${String(date.getDate()).padStart(2, "0")}`;
}

export function getFormattedDate(date) {
  if (!date) return;

  const dateObj = new Date(date);

  const day = dateObj.getDate().toString().padStart(2, "0");
  const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
  const year = dateObj.getFullYear();

  return `${year}-${month}-${day}`;
}

export function getReadableDate(date) {
  if (!date) return;

  const dateObj = new Date(date);

  return dateObj.toLocaleString("no", {
    day: "2-digit",
    month: "long",
    year: "numeric",
  });
}

export function amountFormatter(amount, format, currency) {
  let options = {
    minimumFractionDigits: 0,
  };

  if (currency) {
    options = Object.assign(options, {
      style: "currency",
      currency: currency,
    });
  }

  // Create our number formatter
  const formatter = new Intl.NumberFormat(format, options);

  return formatter.format(amount);
}
