<template>
    <a v-if="isExternalLink" v-bind="$attrs" :href="to" target="_blank" class="inline-flex gap-2 transition ease-in-out">
        <slot />
    </a>
    <router-link v-else v-bind="$props" custom v-slot="{ isActive, href, navigate }">
        <a v-bind="$attrs" :href="href" @click="navigate" class="flex items-center gap-3 p-3 rounded-lg transition hover:bg-silver-tree-50 hover:text-silver-tree-600" :class="isActive ? 'bg-silver-tree-100 text-silver-tree-600 font-bold' : 'text-secondary'">
            <component :is="icon" class="w-6 h-6"></component>
            <span>
                <slot />
            </span>
            <component :is="iconSubmenu" class="w-6 h-6"></component>
        </a>
    </router-link>
</template>

<script setup>
import { ref } from 'vue';
import { RouterLink, useLink } from 'vue-router'

const props = defineProps({
    ...RouterLink.props,
    icon: Function,
    iconSubmenu: Function,
    inactiveClass: String,
})

const { navigate, href, route, isActive, isExactActive } = useLink(props)
const isExternalLink = ref()

</script>