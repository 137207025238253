

<template>
    <div class="grid gap-8">
  
      <router-link to="/dashboard/language" class="flex gap-2 print:hidden">
        <KeyboardArrowLeftIcon class="h-6" />
        <span class="text-lg leading-6 font-semibold">{{ $t('languageAssessment.backToAllEvaluations') }}</span>
      </router-link>
  
      <div v-if="store.application" class="container">
        <section class="grid gap-8">
          <div class="grid grid-cols-[1.75fr_1fr] gap-4 md:gap-8">
            <div>
              <div class="font-bold text-2xl text-dark-green-500 mb-5">{{ store.application.first_name }} {{ store.application.last_name }}</div>
              <div><span class="font-bold">{{ t('form.year_group.title') }}:</span> {{ store.application.year_group }}</div>
              <div><span class="font-bold">{{ t('dashboard.local_church') }}:</span> {{ store.application.local_church.name }}</div>
            </div>
  
            <div v-if="store.application.profile_picture" class="mt-2">
              <img
                :src="directus._url + '/assets/' + store.application.profile_picture.id + '?access_token=' + store.access_token"
                class="rounded-lg" width="150" />
            </div>
          </div>

          <div class="max-w-[310px]">
            <label class="font-bold mb-1 inline-block">{{ t('languageAssessment.form.language_status.title') }}</label>
            <v-select placeholder="---" v-model="store.application.language_status" :reduce="option => option.value"
              :options="store.list.language_status" :selectable="option => option.value" :disabled="noAccess()" />
          </div>
  
          <div class="grid">
            <p class="text-xl font-semibold underline">{{ t('languageAssessment.form.progress') }}</p>

            <table class="language-evaluation">
              <thead>
                <tr>
                  <th></th>
                  <th class="min-w-[80px]"></th>
                  <th class="min-w-[100px]"></th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(subjects, category) in store.languageSubjects" :key="category">
                  <tr class="category">
                    <td class="font-semibold pb-4">{{ t('languageAssessment.form.language_subjects.subjects.' + category) }}</td>
                    <td class="font-semibold text-center pb-4" v-if="category == 'level-a1'">{{ t('languageAssessment.form.language_subjects.passed') }}</td>
                    <td class="font-semibold text-center pb-4" v-if="category == 'level-a1'">{{ t('languageAssessment.form.language_subjects.passed_on') }}</td>
                  </tr>
                  <tr v-for="subject in subjects" :key="subject.id" class="category-subject">
                    <td>{{ subject.title }}</td>
                    <td>
                      <BccToggle v-model="subject.passed" @change="toggleLanguageSubject($event, subject)" :disabled="noAccess()" />
                    </td>
                    <td class="text-center">{{ getFormattedDate(subject.passed_on) }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
  
          <div class="grid gap-2">
            <p class="text-xl font-semibold underline">{{ t('menu.norwegianAssessments') }}</p>
  
            <div class="language-evaluations grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4 my-4">
                <div class="evaluation relative bg-secondary flex flex-col justify-between pl-6 pr-3 py-3 min-h-[140px]" v-for="languageEvaluation in store.languageEvaluations" :key="languageEvaluation.id" :class="languageEvaluation.status && (languageEvaluation.status == 'on_track' || languageEvaluation.status == 'passed') ? 'positive-result': languageEvaluation.status && languageEvaluation.status == 'too_low' ? 'not-acceptable-result' : ''">
                    <div class="font-semibold">{{ t('months.' + languageEvaluation.month_id) }} - {{ languageEvaluation.title }}</div>
                    <div v-if="languageEvaluation.status">
                      <div>
                          <span class="font-semibold">{{ t('languageAssessment.result') }}:</span> {{ t('languageAssessment.form.evaluation_status.' + languageEvaluation.status) }}
                      </div>
                      <div class="note-to-student">
                          <span class="font-semibold">{{ t('languageAssessment.note') }}: </span>
                          <span v-if="!languageEvaluation.showFullNote">{{ languageEvaluation.note_to_student.length > 110 ? languageEvaluation.note_to_student.slice(0,110) + '...' : languageEvaluation.note_to_student ? languageEvaluation.note_to_student : '-' }}</span>
                          <span class="read-more underline cursor-pointer relative ml-2.5 bottom-0.5 text-sm hover:opacity-90" v-if="!languageEvaluation.showFullNote && languageEvaluation.note_to_student.length > 110" @click="languageEvaluation.showFullNote = true">{{ t('languageAssessment.readMore') }}</span>

                          <span v-if="languageEvaluation.showFullNote" class="full-note">{{ languageEvaluation.note_to_student }}</span>
                      </div>
                    </div>
        
                    <div class="evaluation-action underline cursor-pointer text-sm hover:opacity-90"
                      @click="studentEvaluation(languageEvaluation.month_id)">
                        <span v-if="directusStore.isAdmin() || directusStore.userHasRole('norwegian_teacher')">{{ getEvaluationBtn(languageEvaluation) }}</span>
                    </div>
                </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </template>
  
  <script setup>
  import { useRoute, useRouter } from 'vue-router'
  import { onBeforeMount, watch, reactive } from 'vue'
  import { useDirectusStore } from '../stores/directus'
  
  import { KeyboardArrowLeftIcon } from '@bcc-code/icons-vue'
  import { BccToggle } from '@bcc-code/design-library-vue'
  
  import { useI18n } from 'vue-i18n'
  import { getCurrentDate, getFormattedDate } from '../helpers/fields.js'
  
  import vSelect from "vue-select"
  import "vue-select/dist/vue-select.css"
  
  const route = useRoute()
  const router = useRouter()
  const directusStore = useDirectusStore()
  const directus = directusStore.directus
  
  const { t, locale } = useI18n({
    inheritLocale: true,
    useScope: 'local'
  })
  
  let selectOptionsKeysTranslatable = {
    language_status: ['not_started', 'on_track', 'too_low', 'passed'],
  }
  
  let selectOptionsList = {}

  // Translatable values
  Object.entries(selectOptionsKeysTranslatable).map(([key, value]) => {
    selectOptionsList[key] = value.map(val => ({ 'value': val, 'label': t('languageAssessment.form.' + key + '.' + val) }))
  })

  const store = reactive({
    access_token: null,
    selectOptionsKeysTranslatable: selectOptionsKeysTranslatable,
    list: selectOptionsList,
  
    application: null,
    languageSubjects: [],
    languageAssessmentsCount: 0,
    lastLanguageAssessment: null,
    languageEvaluations: [],
  
    updateFailed: false,
    formLoading: false
  })
  
  Object.entries(selectOptionsKeysTranslatable).map(([key, value]) => {
    selectOptionsList[key] = value.map(val => ({ 'value': val, 'label': t('languageAssessment.form.' + key + '.' + val) }))
  })
  
  onBeforeMount(async () => {
    store.access_token = await directus.auth.staticToken
  
    await getApplication()
    await getLanguageSubjects()
    await getLanguageEvaluations()
  
    watch(
      () => store.application.language_status,
      (status) => updateLanguageStatus(status)
    )
  })

  watch(
    locale,
    () => updateSelectOptionsLists()
  )
  
  async function getApplication() {
    const application = await directus.items("applications").readByQuery({
      filter: {
        id: { _eq: route.params.id },
      },
      fields: ['*', 'language_assessments.*.*', 'local_church.*', 'profile_picture.*.*'],
    })
    if (!application.data.length) return
  
    store.application = application.data[0]
  
    store.languageAssessmentsCount = store.application.language_assessments.length - 1
    store.lastLanguageAssessment = store.application.language_assessments[store.languageAssessmentsCount]
  }
  
  async function getLanguageSubjects() {
    const languageSubjects = await directus.items("language_subjects").readByQuery({
      limit: -1
    })
  
    const groupedSubjects = languageSubjects.data.reduce((groups, subject) => {
      if (!groups[subject.category])
        groups[subject.category] = []
  
      const lastAssessmentEvaluation = store.lastLanguageAssessment?.evaluation_subject
        ?.find(evaluation => evaluation.subject_id.key == subject.id)
  
      subject.passed = lastAssessmentEvaluation?.passed
      subject.passed_on = lastAssessmentEvaluation?.passed_on
  
      groups[subject.category].push(subject)
  
      return groups
    }, {})
  
    store.languageSubjects = groupedSubjects
  }

  async function getLanguageEvaluations() {
    const languageEvaluations = await directus.items("language_evaluations").readByQuery({
      limit: -1
    })

    store.languageEvaluations = languageEvaluations.data.map(e => {
      return {
        id: e.id,
        month_id: e.month_id,
        title: e.title,
        status: '',
        note_to_student: '',
        showFullNote: false
      }
    })

    if (!store.lastLanguageAssessment?.language_evaluations)
      return

    const studentPeriodicEvaluationKeys = store.lastLanguageAssessment.language_evaluations.map(e => e.evaluation_id.key)
    const studentPeriodicEvaluations = await directus.items("student_periodic_evaluations").readByQuery({
      filter: {
        id: { _in: studentPeriodicEvaluationKeys },
      },
      fields: ['*'],
      limit: -1
    })

    store.languageEvaluations.forEach(e => {
      const thisLanguageEvaluation = store.lastLanguageAssessment.language_evaluations.find(le => le.month_id == e.month_id)
      if (!thisLanguageEvaluation) return

      const thisStudentPeriodicEvaluation = studentPeriodicEvaluations.data.find(spe => spe.id == thisLanguageEvaluation.evaluation_id.key)

      e.status = thisStudentPeriodicEvaluation.status
      e.note_to_student = thisStudentPeriodicEvaluation.note_to_student

      return
    })
  }
  
  async function toggleLanguageSubject(e, subject) {
    subject.passed_on = e.target.checked ? getCurrentDate() : null

    const languageSubjects = Object.values(store.languageSubjects).flat()
    const evaluationSubjects = languageSubjects
      .filter(subject => subject.passed)
      .map(subject => {
        return {
          "subject_id": {
            "key": subject.id,
            "collection": "language_subjects"
          },
          "passed": subject.passed,
          "passed_on": subject.passed_on
        }
      })

    store.formLoading = true
    
    if (!store.lastLanguageAssessment) {
      store.lastLanguageAssessment = await directus.items('language_assessments').createOne({
        application_id: store.application.id,
        evaluation_subject: evaluationSubjects
      })
    }
    else {
      await directus.items('language_assessments').updateOne(store.lastLanguageAssessment.id, {
        evaluation_subject: evaluationSubjects
      })
    }
  
    store.formLoading = false
  }

  function getEvaluationBtn(evaluation) {
    return !evaluation.status
      ? t('languageAssessment.giveEvaluation')
      : t('languageAssessment.editEvaluation')
  }

  function studentEvaluation(month) {
      router.push({ path: '/application/' + store.application.id + '/language-assessment/' + month })
  }

  async function updateLanguageStatus(status) {
      await directus.items('applications').updateOne(store.application.id, {
          'language_status': status
      })
  }

  function updateSelectOptionsLists() {
    Object.entries(store.selectOptionsKeysTranslatable).map(([key, value]) => {
      store.list[key] = value.map(val => ({ 'value': val, 'label': t('languageAssessment.form.' + key + '.' + val) }))
    })
  }

  function noAccess() {
    return !directusStore.userHasRole('norwegian_teacher') && !directusStore.isAdmin()
  }
</script>