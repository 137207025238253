import { defineStore } from "pinia";
import { Directus } from "@directus/sdk";
import { useAuthStore } from "./auth";

export const useDirectusStore = defineStore("directus", {
  state: () => ({
    directus: null,
    user: null,
  }),
  getters: {
    getDirectus() {
      return this.directus;
    },

    getDirectusApiUrl() {
      if (window.location.hostname == "my.ateam.bcc.no")
        return "https://admin.ateam.bcc.no";
      else if (window.location.hostname == "dev.my.ateam.bcc.no")
        return "https://dev.admin.ateam.bcc.no";

      return "http://localhost:8055";
    },

    userHasRole: (state) => {
      return (role) => state.user.role?.slug == role;
    },
  },
  actions: {
    async setupDirectus() {
      const authStore = useAuthStore();

      const token = await authStore.getIdToken;
      if (!token) return null;

      this.directus = new Directus(this.getDirectusApiUrl, {
        auth: {
          staticToken: token,
        },
      });
    },

    async setUser() {
      if (!this.user) {
        this.user = await this.directus.users.me
          .read({
            fields: [
              "*",
              "role.slug",
              "administrator_for.local_churches_id.id",
              "employments.id",
              "employments.location.id",
              "employments.employer_id.id",
              "employments.employer_id.tags",
            ],
          })
          .catch(() => {
            console.log("Failed to get the user from the database!");
          });
      }
    },

    isAdmin() {
      return (
        this.userHasRole("administrator") || this.userHasRole("assistent_admin")
      );
    },
  },
});
