<template>
  <div v-if="user" class="grid gap-8">
    <div class="container min-w-0">
      <header class="flex gap-6 mb-4 flex-col md:flex-row">
        <router-link to="/" class="disable-link flex gap-2">
          <span class="text-xl leading-6 font-semibold">{{ $t('menu.wnt-placements') }}</span>
        </router-link>

        <BccInput class="md:ml-auto" v-model="store.search_name" :placeholder="t('dashboard.filter.search')" />
      </header>

      <div class="flex gap-2 mb-4">
        <v-select v-if="store.employers.length" v-model="store.employer_id" class="w-72 mr-2"
          :placeholder="t('dashboard.filter.employer')" :reduce="option => option.value" :options="store.employers" />

        <v-select v-if="store.yearGroups.length" class="w-72" v-model="store.search_yearGroup"
          :placeholder="t('dashboard.filter.yearGroup')" :reduce="option => option.value" :options="store.yearGroups" />
      </div>

      <BccTable :columns="columns()" :items="sortedEmployments" v-model:sortBy="store.sortBy"
        v-model:sortDirection="store.sortDirection" class="max-w-full overflow-x-auto quick-fix_white-space">

        <template #item.display.potential_placement="{ item }">
          <BccBadge v-for="placement in item.display.potential_placement" context="info" class="mr-1">
            {{ t('dashboard.potential_placement.' + placement) }}
          </BccBadge>
        </template>

        <template #item.actions="{ item }">
          <BccButton variant="tertiary" size="sm" class="w-28 justify-start" :padding="false" :icon="ChevronRightIcon" iconRight
            @click="goToApplication(item)">{{ t('dashboard.actions.seeProfile') }} </BccButton>
        </template>

      </BccTable>

      <div v-if="store.isLoading" class="text-center w-full my-4">
        <BccButton>{{ t('loading') }}</BccButton>
      </div>

      <div v-if="!store.isLoading && !sortedEmployments.length" class="m-4">
        <p>{{ t('dashboard.noApplicationAvailable') }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, reactive, watch, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useDirectusStore } from '../stores/directus'

import { BccButton, BccInput, BccTable, BccBadge } from '@bcc-code/design-library-vue'
import { ChevronRightIcon } from '@bcc-code/icons-vue'

import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"
import { useRouter } from 'vue-router'

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
})

const router = useRouter()
const directusStore = useDirectusStore()
const user = directusStore.user
const directus = directusStore.directus

const store = reactive({
  isLoading: true,
  search_name: '',
  yearGroups: [],
  search_yearGroup: localStorage.getItem('yearGroup'),
  employments: [],
  employers: [],
  employer_id: parseInt(localStorage.getItem('employerId')) || null,
  sortBy: 'display.arrival_date',
  sortDirection: 'descending'
})

const columns = () => [
  {
    text: t('dashboard.name'),
    key: 'display.name'
  },
  {
    text: 'YEP',
    key: 'display.yep'
  },
  {
    text: t('dashboard.potential_placement.title'),
    key: 'display.potential_placement',
    sortable: false
  },
  {
    text: t('dashboard.arrival_date'),
    key: 'display.arrival_date'
  },
  {
    text: t('dashboard.departure_date'),
    key: 'display.departure_date'
  },
  {
    text: t('dashboard.actions.title'),
    key: 'actions',
    sortable: false
  }
]

onBeforeMount(async () => {
  if (directusStore.userHasRole('applicant'))
    router.push({ path: '/' })

  await getEmployments()  
  await getEmployers()

  store.isLoading = false
})

const sortedEmployments = computed(() => {
  return store.employments
    .filter(e => {
      if (!store.employer_id) return true
      return e.employer_id?.id == store.employer_id
    })
    .filter(e => {
      return store.search_yearGroup
        ? e.application.year_group == store.search_yearGroup : true
    })
    .filter(e => {
      return (e.user_id.first_name + ' ' + e.user_id.last_name).toLowerCase().includes(store.search_name.toLowerCase())
        || (e.user_id.last_name + ' ' + e.user_id.first_name).toLowerCase().includes(store.search_name.toLowerCase())
    })
})

watch(
  () => store.employer_id,
  (tag) => {
    tag != null
      ? localStorage.setItem('employerId', tag)
      : localStorage.removeItem('employerId')
  }
)

watch(
  () => store.search_yearGroup,
  (tag) => {
    tag != null
      ? localStorage.setItem('yearGroup', tag)
      : localStorage.removeItem('yearGroup')
  }
)

async function getEmployments() {
  let employments = await directus.items("employments").readByQuery({
    fields: [
      'id', 'arrival_date', 'departure_date', 'employer_id.*',
      'user_id.id', 'user_id.first_name', 'user_id.last_name', 'user_id.applications.*'
    ],
    limit: -1
  })

  let employmentForEachApplication = []

  employments.data.forEach(e => {
    e.applications = e.user_id?.applications.filter(a => [
      'ready_for_interview',
      'interview_scheduled',
      'ready_for_placement',
      'placement_confirmed',
      'active_participation'
    ].includes(a.status))

    if (!e.applications) return

    e.applications.forEach(a => {
      employmentForEachApplication.push({
        id: e.id,
        arrival_date: e.arrival_date,
        departure_date: e.departure_date,
        employer_id: e.employer_id,
        user_id: {
          id: e.user_id.id,
          first_name: a.first_name,
          last_name: a.last_name
        },
        application: a
      })
    })
  });

  store.employments = transformEmploymentsData(employmentForEachApplication)

  // Get year groups from employments
  const yearGroups = store.employments
    .filter(e => e.application.year_group)
    .map(e => {
      return e.application.year_group
    })
    .flat()
    .map(year_group => {
      return { 'value': year_group, 'label': t('form.year_group.title') + ": " + year_group }
    })
    .sort((a, b) => {
      if (a.label < b.label) return -1
      if (a.label > b.label) return 1
      return 0
    })

  // Set unique values
  store.yearGroups = [...new Map(yearGroups.map(c => [c.label, c])).values()]

  // Filter by the only value in the array
  if (store.yearGroups.length == 1)
    store.search_yearGroup = store.yearGroups[0].value
}

function transformEmploymentsData(data) {
  return data.map(item => {
    const display = {}

    display.name = `${item.user_id.first_name} ${item.user_id.last_name}`
    display.yep = t('form.yesOrNo.' + item.application.yep)
    display.potential_placement = item.application.potential_placement
    display.arrival_date = item.arrival_date
    display.departure_date = item.departure_date

    return { ...item, display }
  })
}

async function getEmployers() {
  if (!store.employments.length) return

  store.employers = store.employments
    .filter(e => e.employer_id)
    .map(e => { return { 'value': e.employer_id.id, 'label': e.employer_id.legal_entity_name } })
    .sort((a, b) => {
      if (a.label < b.label) return -1
      if (a.label > b.label) return 1
      return 0
    })

  // Remove duplicates
  store.employers = uniqByKeepLast(store.employers, it => it.label)

  // Filter by the only value in the array
  if (store.employers.length == 1)
    store.employer_id = store.employers[0].value
}

function goToApplication(employment) {
  router.push({ path: '/application/' + employment.application.id + '/wnt/' + employment.employer_id.id })
}

function uniqByKeepLast(data, key) {
  return [...new Map(data.map(x => [key(x), x])).values()]
}
</script>